import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import CT from '../../assets/media/do.png';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from '../../assets/media/car.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const Product = () => {
  
  return (
    <>

  <Container fluid className="container category">
     <div className="hyperlinks">
      <a href="" >Home</a> {` `}/
      <a href="" id="activeLink">Product</a>
      <h2>Pet Boarding</h2>
     </div>

    
   
   


     <div className="category-services">


        <div className="category-service-repeat">
            <div className="category-img">
                <img src={CT} />
            </div>
            <div className="category-details">
                <h2>Service Name</h2>
                <h3 id="price">₹ 1,499</h3>
                <p><span id="review">128 Reviews</span> <span id="number">9.2</span></p>
                <p id="desc" numberOfLines={1}>Upgraded and spacious kennel/cage - Premium pet food provided</p>
                <div className="addto">
                 <button id="add">Book now</button>
                 <button id="view">View Details</button>
                </div>
            </div>
           
        </div>


        
     </div>
    </Container>

     
    </>
  );
}

export default Product;
