// router.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/authScreens/Login";
import Homescreen from "../pages/pages/homescreen";
import Category from "../pages/pages/Category";
import Product from "../pages/pages/Product";
import BookService from "../pages/pages/BookService";
import NotFound from "../pages/pages/404";
import Signup from "../pages/authScreens/Signup";
import MyBookings from "../pages/pages/MyBookings";
import PackersMovers from "../pages/pages/packersMovers";
import Privacy from "../pages/pages/privacy";
import About from "../pages/pages/about";
import Terms from "../pages/pages/terms";
import MyCart from "../pages/pages/Cart";
import SearchPage from "../pages/pages/SearchPage";
import PetAdoption from "../pages/pages/Adoption/PetAdoption";
import BookServicePet from "../pages/pages/BookPetAdoption";
import Adoption from "../pages/pages/Adoption/ListAdoption";
import MyPets from "../pages/pages/Adoption/MyPets";
import EditAdoption from "../pages/pages/Adoption/editPet";
import Dog from "../pages/pages/Adoption/DogType";
import CatType from "../pages/pages/Adoption/CatType";
import OtherType from "../pages/pages/Adoption/OtherType";
import CheckoutPage from "../pages/pages/commerce/Checkout";
function Router() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Homescreen />} />
      <Route path="/search/:keyword" element={<SearchPage />} />
      <Route path="/new-user" element={<Signup />} />
      <Route path="/category/:categoryName" element={<Category />} />
      <Route path="/pet-adoption" element={<PetAdoption />} />
      <Route path="/product" element={<Product />} />
      <Route path="/my-bookings/:tab?" element={<MyBookings />} />
      <Route path="/cart" element={<MyCart />} />
      <Route path="/book-service/:name" element={<BookService />} />
      <Route path="/book-service-adoption/:name" element={<BookServicePet />} />
      <Route path="/packers-and-movers/" element={<PackersMovers />} />
      <Route path="/privacy-policy/" element={<Privacy />} />
      <Route path="/about-us/" element={<About />} />
      <Route path="/terms-condition/" element={<Terms />} />
      <Route path="/adoption" element={<Adoption />} />
      <Route path="/adoption/edit/:serviceId" element={<EditAdoption />} />
      <Route path="/my-pets" element={<MyPets />} />
      <Route path="/pet-type/dog" element={<Dog />} />
      <Route path="/pet-type/cat" element={<CatType />} />
      <Route path="/pet-type/other" element={<OtherType />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/checkout/:serviceId" element={<CheckoutPage />} />
    </Routes>
  );
}

export default Router;
