import React from "react";
import Container from "react-bootstrap/Container";

function Terms() {
  return (
    <Container>
      <div className="mgTagline">
        <div className="tagLeftPrivacy mobile">
          <h2>Terms & Condition</h2>
          <h3> INTRODUCTION</h3>
          <p>
          Please read these Terms and Conditions ("Terms") carefully before using the pet care services provided by RAA EASY HAI BRO SERVICES PRIVATE LIMITED ("we," "us," or "our"). By accessing or using our services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our services.

          </p>
          <h3> Refund Policy:</h3>
      <p>
        In circumstances where refunds are not applicable, such as with certain digital products or services, we are committed to providing clear communication regarding this policy prior to your purchase or engagement.
      </p>
          <h3>Service Agreement</h3>
          <p>
          By using our services, you agree to abide by the terms and conditions outlined in this agreement.
          </p>
          <h3> ELIGIBILITY</h3>
          <p>
          You must be at least 18 years old to use our services.
b. By using our services, you represent and warrant that you have the legal capacity to enter into this agreement.
          </p>
          <h3>Service Availability</h3>
          <p>
          Our services are available in specific locations, and service availability may vary. We reserve the right to modify or discontinue services without notice.

          </p>
          <h3>User Responsibilities</h3>
          <p>
          a. You are responsible for providing accurate and up-to-date information about yourself and your pets.
b. You agree not to misuse our services or engage in any activity that may interfere with the proper functioning of our platform.
          </p>
          <h3>Payment</h3>
          <p>
Payment for services must be made through the designated payment methods on our platform.
            <br />
            Prices for services are subject to change, and we reserve the right to adjust pricing at any time.
            <br />
            {/* <h3>Cancellation and Refund</h3>
            Cancellation policies may apply, and refunds are subject to our discretion.
            <br/>
            Cancellation fees may be charged for cancellations made within a specified period.
            <h3>Intellectual Property</h3>
            All content, trademarks, and intellectual property on our platform are the property of RAA EASY HAI BRO SERVICES PRIVATE LIMITED.
     <h3>Privacy</h3> */}
     <p>Our Privacy Policy governs the collection and use of your personal information. By using our services, you consent to the terms outlined in our Privacy Policy.</p>
     <h3>Limitation of Liability</h3>
     <p>We are not liable for any damages, losses, or liabilities arising from the use of our services.</p>
     <h3>Indemnification</h3>
     <p>You agree to indemnify and hold RAA EASY HAI BRO SERVICES PRIVATE LIMITED harmless from any claims, losses, or damages arising from your use of our services.</p>
     <h3>Applicable Law</h3>
     <p>This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of domain name www.easyhaibro.com (“Platform”), including the related mobile site and mobile application (hereinafter referred to as “Easy Hai Bro”) and all the laws of the Republic of India are and shall be the governing laws for any purpose whatsoever herein</p>
     <h3>Changes to Terms</h3>
     <p>We reserve the right to modify these Terms at any time. Changes will be effective upon posting on our platform.</p>

     <h3>Contact Information</h3>
     <p>If you have any questions or concerns about these Terms and Conditions, please contact us at info@easyhaibro.com.</p>
     <p>By using our services, you agree to comply with these Terms and Conditions. If you do not agree with these Terms, please refrain from using our services.</p>

            <br />
            3- harms minors in any way,
            <br />
            4-infringes any patent, trademark, copyright or other proprietary
            rights,
            <br />
            5- violates any law for the time being in force,
            <br />
            6- deceives or misleads the addressee about the origin of such
            messages or communicates any information, which is grossly offensive
            or menacing in nature,
            <br />
            7- impersonate another person,
            <br />
            8- contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource, threatens the unity, integrity, defence,
            security or sovereignty of India, friendly relations with foreign
            states, or public order or causes incitement to the commission of
            any cognisable offence or prevents investigation of any offence or
            is insulting to any other nation.
            <br />
            The user shall not use any deep-link, robot, spider or other
            automatic device, program, algorithm or methodology, or any similar
            or equivalent manual process, to access, acquire, copy or monitor
            any portion of the Website/Content or its content, or in any way
            reproduce or circumvent the navigational structure or presentation
            of the Website/App, materials or its content, to obtain or attempt
            to obtain any materials, documents or information through any means
            not specifically made available through the . The user may not
            access (or attempt to access) any of the services by any means other
            than through the interface that is provided through the Website/App,
            unless user have been specifically allowed to do so in a separate
            agreement with easyexport. The user should not do anything that
            disables, overburdens, or impair the proper functioning of the
            Website/App. Users should specifically agree that they will not
            engage in any activity that interferes with or disrupts the services
            or the servers and networks which are connected to the services
            provided through the Website/App. The user undertakes not to attempt
            to circumvent or modify any security technology or software that is
            part of the Website/App. The user also agrees not to engage or
            assist any other person, to circumvent or modify any security
            technology or software that is part of the Website/App. The user
            should also not obscure or obstruct any features of the Website/App.
            The user undertakes not to copy, modify, create a derivative work
            of, reverse engineer, decompile or otherwise attempt to extract the
            source code of the Website/App or any part thereof without a
            specific written permission from easyexport to do so. The lists
            multiple products for sale offered by sellers and hosts comments of
            numerous users and it is not possible for easyexport to be aware of
            the contents of each product listed for sale, or each comment or
            review that is displayed. The user acknowledges and agrees that by
            accessing or using the Website/App or availing the services, user
            may be exposed to content that the user may consider offensive,
            indecent or otherwise objectionable. easyexport disclaims all
            liabilities arising in relation to such Objectionable Content on the
            Website/App. Any user if exposed to such Objectionable Content as
            aforesaid is requested to immediately report the same to the
            Grievance Officer in the manner provided under these Terms and the
            Website/App will endeavour to remove such content as soon as
            possible after investigation. The user agrees and acknowledges that
            easyexport is not the seller of the products and easyexport shall in
            no manner be deemed to be the seller of the products on the
            Website/App. easyexport is only facilitating purchase of the products
            by the user from the vendors by providing use and access to the
            Website/App. The user expressly consents to receive communications
            from easyexport in relation to the user’s use and access of the
            Website/App and its services and agrees that such communications
            shall not be treated as an unsolicited communication. The user
            agrees that easyexport may, at any time, modify or discontinue all or
            part of the Website/App, charge, modify or waive fees required to
            use the Website/App, or offer opportunities to some or all users.
          </p>
          <h3>VII. INFRASTRUCTURE</h3>
          <p>
            To use the Website/App users require Internet connectivity or
            appropriate telecommunication links and the service does not include
            the provision of any computer system, resource, terminal, server,
            equipment, mobile or any devices. easyexport shall not have any
            responsibility or liability for any computer system, resource,
            terminal, server, equipment, mobile or any devices necessary for
            user to use and access the nor shall be liable for any costs
            incurred on account of the user’s Internet or appropriate
            telecommunication links usage. easyexport would not be liable for
            damages, viruses or other code that may affect the user’s computer
            system, resource, terminal, server, equipment, mobile or any other
            devices while using the and easyexport does not ensure that the
            Website/App will be compatible with all hardware and software used
            by the user. The user must comply with applicable third party terms,
            if any, such as mobile device agreement or wireless data service
            agreement etc. while using the Website/App.
          </p>
          <h3>VIII. USE OF CONTENT</h3>
          <p>
            Any content that the user posts or material it submits, the user
            grants easyexport and its affiliates and associates a non-exclusive,
            royalty-free, irrevocable, perpetual and fully sub-licensable rights
            to use, reproduce, publish, distribute and display such content
            throughout the world in any mode medium or manner now known or
            developed in future; and the right to use the name that the user
            submits in connection with such content, if the user chooses. The
            user agrees that the rights the user grants as above are irrevocable
            during the entire period of protection of the user’s intellectual
            property rights associated with such content and material. The user
            agrees to waive the user’s right to be identified as the author of
            such content and materials and the user’s right to object to
            derogatory treatment of such content or material. The user agrees to
            perform all further acts and execute deeds and documents necessary
            to perfect the above rights granted by the user to easyexport. The
            user represents and warrants that the user owns or otherwise
            controls all of the rights to the content and material that the user
            posts or that the user otherwise provides on or through the ; that,
            as at the date that the content or material is submitted to the :
            (i) the content and material is accurate; (ii) use of the content
            and material does not breach any applicable Indian law or the
            policies or guidelines or Additional Terms (iii) the content and
            material do not breach these Terms and Privacy Policy.
          </p>
          <h3>IX. THIRD PARTY WEBSITE/APPS</h3>
          <p>
            The Website/App may contain links to other Website/Apps and the
            Website/App are not in any way responsible for the terms of use or
            content of such Website/Apps and expressly disclaims all liability
            associated with the content and use of these Website/Apps. The
            linked Website/Apps are not under the control of easyexport and users
            are encouraged to read the terms of use and privacy policy of each
            and every linked Website/App. If users decide to access any of the
            third-party Website/Apps linked to the Website/App, it is at the
            risk of the users to access and use such linked Website/Apps and
            easyexport shall not be responsible in any manner whatsoever.
          </p>
       
     
       

        </div>
      </div>
    </Container>
  );
}

export default Terms;
