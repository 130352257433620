// ReviewsSection.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactStars from 'react-stars';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const reviewsData = [
  {
    id: 1,
    author: 'Aman Sharma',
    review: 'EHB! gives me great peace of mind while I am at work. I get to the minute potty information and a picture of his smile at the end of walk. Thank you Wag!!',
    image: 'https://api.multigrad.in/open/media/?file=1695584577430.png',
    stars: 5,
  },
  {
    id: 2,
    author: 'Sandeep Shakya',
    review: 'Easy process, affordable prices, amazing walker! Loved it',
    image: 'https://api.multigrad.in/open/media/?file=1695584713183.png',
    stars: 4.5,
  },
  {
    id: 3,
    author: 'Krishna Mohan Yadav',
    review: 'Love this app! So does my dog!!',
    image: 'https://api.multigrad.in/open/media/?file=1696525312270.png',
    stars: 4,
  },
  {
    id: 4,
    author: 'Abhishek Yadav',
    review: `The EHB! app is great for times I can't get home to walk my dog. The walkers leave a nice recap of my dog's behavior! Best of all, my dog loves meeting new people who come walk him!`,
    image: 'https://api.multigrad.in/open/media/?file=1695584667668.png',
    stars: 5,
  },

];


const ReviewsSection = () => {
    const screenWidth = window.innerWidth;

    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
    };
  
    if (screenWidth <= 1200) {
      sliderSettings.slidesToShow = 1;
    } else {
      sliderSettings.slidesToShow = 3;
    }

  return (
    <Container fluid className="container lg-ds">
      <Row>
        <Col lg={12} md={12} sm={12}>
    <div className="reviews-section home">
      <h2>Our happy pet lovers!</h2>
      <Slider {...sliderSettings}>
        {reviewsData.map(review => (
          <div key={review.id} className="review-item">
              <img src={review.image} alt={`${review.author}'s Image`} />
            <ReactStars
            className='review'
              count={5}
              size={20}
              color2={'#0047ba'}
              edit={false}
              value={review.stars}
            />
         
            <p>{review.review}</p>
            <p>- {review.author}</p>
          </div>
        ))}
      </Slider>
    </div>
        
    </Col>

        
      
</Row>
</Container>
  );
};

export default ReviewsSection;
