// Import necessary modules and components
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { HiOutlineCash } from "react-icons/hi";
import { GrUserExpert } from "react-icons/gr";
import { GiTransparentSlime } from "react-icons/gi";
const Hyperlinks = () => {
    const { categoryName } = useParams();

    const categoryNameParam = categoryName
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  

  return (
<div className="hyperlinks">
<Link to="/">Home</Link> / <span id="activeLink">{categoryNameParam}</span>
<h2>{categoryNameParam}</h2>
<p>Service that fits your budget with quality assurance!</p>
<div className="ehb-prtotection">
   <div className="ehb-prt-div one">
   <VscWorkspaceTrusted />

    <div className="ehb-menu">
      <h2>100% Satisfaction Guaranteed:</h2>
      <p>If you're not satisfied, we offer a full refund to ensure your peace of mind</p>
    </div>
   
   </div>
   <div className="ehb-prt-div two">
   <HiOutlineCash />
    <div className="ehb-menu">
      <h2>Cash Payment Available</h2>
      <p>Conveniently pay in cash for our services, providing you with flexibility in payment options.</p>
    </div>
   
   </div>
   <div className="ehb-prt-div three">
   <GrUserExpert />

    <div className="ehb-menu">
      <h2>Expert Care Professionals:</h2>
      <p>Our passionate team of experienced professionals ensures the highest standard of care for your pets.</p>
    </div>
   
   </div>

   <div className="ehb-prt-div three">
   <GiTransparentSlime />

    <div className="ehb-menu">
      <h2>Transparent and Flexible:</h2>
      <p>Open communication and fair, straightforward policies for a positive experience.</p>
    </div>
   
   </div>
</div>
</div>

);
};

export default Hyperlinks;