import React, { useState, useEffect } from 'react';
import axios from 'axios';

const OrderSummary = () => {
  const [serviceData, setServiceData] = useState({});
  const [loading, setLoading] = useState(true);
  const url = window.location.href;
  const userToken = localStorage.getItem("userToken");
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await axios.get(`https://ehb.easyhaibro.com/auth/v1/service/${lastPart}`, {
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
        });

        setServiceData(response.data);
      } catch (error) {
        console.error("Error fetching service details:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchServiceDetails();
  }, [userToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='inner-section-checkout slots'>
      <div className='ehb-row'>
        <div className='ehb-image'>
          <img src={serviceData.serviceImage} alt={serviceData.serviceName} />
        </div>
        <div className='ehb-column service-d'>
          <h2>{serviceData.serviceName}</h2>
          <h2>₹ {serviceData.servicePrice}</h2>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
