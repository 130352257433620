import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/media/logo.png';
import axios from 'axios';
import getLogo from '../../screens/commonScreens/config';
import { FaPlus } from "react-icons/fa";
import { TbDogBowl } from "react-icons/tb";
import { TbHelpSquareRoundedFilled } from "react-icons/tb";
import { IoLogOut } from "react-icons/io5";
import LoginModal from '../../modal/Login';
import { FiShoppingCart } from "react-icons/fi";
import { FaClipboardList } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { FaRegUserCircle } from "react-icons/fa";
import { FaLocationArrow } from "react-icons/fa";
function BasicExample() {
  const logoSrc = getLogo();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [totalCounts, setTotalCounts] = useState(0);
  const userToken = localStorage.getItem("userToken");
  const { keyword } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState('');
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://ehb.easyhaibro.com/auth/v1/user', {
          headers: {
            'Authorization': `Bearer ${userToken}`
          },
        });

       
        setData(response.data.data)
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData(); 
    fetchDataCount();

    const intervalId = setInterval(() => {
    
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, []); 

  const fetchDataCount = async () => {
    try {
      const response = await axios.get('https://ehb.easyhaibro.com/auth/v1/cart/total', {
        headers: {
          'Authorization': `Bearer ${userToken}`
        },
      });

      setTotalCounts(response.data.totalCount)
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };



const handleLogout =()=>{
  localStorage.clear();
  window.location.reload();

}


const handleSearch = () => {
  navigate(`/search/${searchTerm}`);
  window.location.reload();
};


  return (
    <>

<Navbar expand="lg" className="bg-body-tertiary ct-nav">
      <Container>
        <Navbar.Brand href="/">
          <img src={logoSrc} alt="easyhaibro" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
            <div className='search-bar'>
              <input 
                type="text" 
                value={searchTerm} 
                onChange={(e) => setSearchTerm(e.target.value)} 
                placeholder={`Search... ${searchTerm}`} 
              />
              <button onClick={handleSearch}>Search</button>
            </div>
          </Nav>

          <Nav className='nav-auth'>
            
         {/* <NavDropdown title="Mumbai" id="basic-nav-dropdown" /> */}
            <NavDropdown.Item id="operation"><FaLocationArrow /> Mumbai</NavDropdown.Item>
            
            {!userToken && 
            <>
          

             <button onClick={handleOpenModal} id="login">Login</button>
           
             </>
}
{userToken && (
              <>
                <NavDropdown.Item href="/cart"> <FiShoppingCart id="cart"  /> <span id="counts">{totalCounts}</span></NavDropdown.Item>
              
                <NavDropdown title="List your pet" id="basic-nav-dropdown" > 
                  <NavDropdown.Item href="/adoption" id='services'><FaPlus /> For Sale</NavDropdown.Item>
                  <NavDropdown.Item href="/my-pets" id='services'>   <FaClipboardList /> My Pets</NavDropdown.Item>
                </NavDropdown>
             
          
                <NavDropdown title={data.fullName} id="user-dropdown">
               
                <NavDropdown.Item href="/my-bookings/myProfile"><FaRegUserCircle />{` `}My Profile</NavDropdown.Item>
                  <NavDropdown.Item href="/my-bookings/myBookings"><TbDogBowl />{` `}My Bookings</NavDropdown.Item>
                  
                  <NavDropdown.Item href="https://api.whatsapp.com/send/?phone=+919004044078&text&type=phone_number&app_absent=0"><TbHelpSquareRoundedFilled />{` `}Help Center</NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogout}><IoLogOut />
 {` `}Logout</NavDropdown.Item>
               
                  
                </NavDropdown>
              </>
            )}
           
         
          </Nav>
        </Navbar.Collapse>
      </Container>
      <LoginModal show={showModal} handleClose={handleCloseModal}  handleLogin={handleOpenModal} />
    </Navbar>
     {/* <div className="mobile_nav">
       <div className='ehb-row'>
         <div className='ehb-section'>
         <AiFillHome/>
           <p>Home</p>
         </div>
         <div className='ehb-section'>
           <FaClipboardList/>
           <p>Bookings</p>
         </div>
         <div className='ehb-section'>
           <FaWhatsapp/>
           <p>Support</p>
         </div>
         <div className='ehb-section'>
           <AiOutlineUser/>
           <p>Account</p>
         </div>
       </div>
     </div> */}
    </>
    
  );
}

export default BasicExample;