import localLogo from '../../assets/media/local_logo.png';
import devLogo from '../../assets/media/dev_logo.png';
import prodLogo from '../../assets/media/logo.png';

const getLogo = () => {
  const hostname = window.location.hostname;
  switch (hostname) {
    case 'localhost':
      return localLogo;
    case 'dev.easyhaibro.com':
      return devLogo;
    case 'easyhaibro.com':
      return prodLogo;
    default:
      return prodLogo; 
  }
};

export default getLogo;
