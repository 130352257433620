import React, { useState } from 'react';
import LoginDetails from './LoginDetails';
import Address from './Address';
import OrderSummary from './OrderSummary';
import PaymentOptions from './PaymentOptions';
import Slots from './Slots';
import { FaRegUser } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaCheckToSlot } from "react-icons/fa6";
import { MdOutlinePets } from "react-icons/md";
import { MdOutlinePayment } from "react-icons/md";
import PaymentSummary from './PaymentSummary';
import CouponCode from './CouponCode';


const CheckoutPage = () => {
  const [openSection, setOpenSection] = useState('address');

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className='container checkout'>
    <div className='ehb-row'>

    <div className="ehb5 sticky">
      <div className={`section ${openSection === 'login' ? 'active' : ''}`}>
        <button className="btn btn-primary" onClick={() => toggleSection('login')}>
         <FaRegUser /> Personal Details
        </button>
        {openSection === 'login' && <LoginDetails />}
      </div>

      <div className={`section ${openSection === 'address' ? 'active' : ''}`}>
        <button className="btn btn-primary" onClick={() => toggleSection('address')}>
         <FaLocationDot /> Delivery Address
        </button>
        {openSection === 'address' && <Address />}
      </div>


      <div className={`section ${openSection === 'slots' ? 'active' : ''}`}>
        <button className="btn btn-primary" onClick={() => toggleSection('slots')}>
         <FaCheckToSlot /> Select Time & Date
        </button>
        {openSection === 'slots' && <Slots />}
      </div>

      <div className={`section ${openSection === 'summary' ? 'active' : ''}`}>
        <button className="btn btn-primary" onClick={() => toggleSection('summary')}>
         <MdOutlinePets /> Order Summary
        </button>
        {openSection === 'summary' && <OrderSummary />}
      </div>

      <div className={`section ${openSection === 'payment' ? 'active' : ''}`}>
        <button className="btn btn-primary" onClick={() => toggleSection('payment')}>
         <MdOutlinePayment /> Payment Options
        </button>
        {openSection === 'payment' && <PaymentOptions />}
      </div>

      
    </div>



    <div className="ehb5">
        <CouponCode />
        <PaymentSummary />
    </div>
    </div>
    <div className='summary'>
        <h2>Cancellation & reschedule policy</h2>
        <p>Free cancellations/reschedules if done more than 3 hrs before the service or if a professional isn’t assigned. A fee will be charged otherwise.</p>
       <a href="/privacy-policy" target='_blank'>Learn more</a>
      </div>
    </div>
  );
};

export default CheckoutPage;
