import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import DOMPurify from 'dompurify';
import moment from "moment-timezone";
import { Link, useNavigate } from 'react-router-dom';
import { ShimmerPostDetails } from "react-shimmer-effects";

const MyProfileComp = () => {
  const [userDetails, setUserDetails] = useState({
    fullName: '',
    phone: '',
    address: '',
    apartmentNo: '',
    houseNo: '',
    pincode: '',
    country: '',
    lat: '',
    long: '',
    email:''
  });
  const [editMode, setEditMode] = useState(false);
  const [updatedUserDetails, setUpdatedUserDetails] = useState({
    fullName: '',
    phone: '',
    address: '',
    apartmentNo: '',
    houseNo: '',
    pincode: '',
    country: '',
    lat: '',
    long: '',
    email:""
  });
  const [loading, setLoading] = useState(true); // State to track loading state
  const [saving, setSaving] = useState(false); // State to track saving state
  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get('https://ehb.easyhaibro.com/auth/v1/user', {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      });
      console.log("User Details:", response.data.data);
      setUserDetails(response.data.data);
      setLoading(false); // Data loaded, set loading state to false
    } catch (error) {
      console.error("Error fetching user details:", error);
      setLoading(false); // Error occurred, set loading state to false
    }
  };

  useEffect(() => {
    fetchUserDetails();
    // Fetch current location when component mounts
    getCurrentLocation();
  }, [userToken]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUserDetails({ ...updatedUserDetails, [name]: value });
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
    if (!editMode) {
      // Reset updated user details to current user details when switching to edit mode
      setUpdatedUserDetails({ ...userDetails });
    }
  };

  const handleSave = async () => {
    try {
      setSaving(true); // Set saving state to true
      await axios.patch(`${process.env.REACT_APP_HOST}user`, updatedUserDetails, {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      });
      setUserDetails(updatedUserDetails);
      setEditMode(false);
      setSaving(false); // Set saving state to false after successful save
    } catch (error) {
      console.error("Error saving user details:", error);
      setSaving(false); // Set saving state to false if an error occurs
    }
  };

  // Function to ask for current location and update latitude and longitude
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setUpdatedUserDetails({
          ...updatedUserDetails,
          lat: latitude,
          long: longitude
        });
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  return (
    <>
      <div className="category-services">
        <div className="myProfile">
          <h1>My Profile</h1>
          {loading ? ( // If data is loading, show shimmer effect
            <ShimmerPostDetails />
          ) : (
            <div className="ehb-row ehb-profile">
           <div className="ehb5">
           <label>Full Name</label>
              {editMode ? (
                <input name="fullName" value={updatedUserDetails.fullName} onChange={handleInputChange} />
              ) : (
                <div  id="detail">{userDetails.fullName}</div>
              )}
           </div>
           <div className="ehb5"> 
           <label>Email Address</label>
              {editMode ? (
                <input name="email" value={updatedUserDetails.email} onChange={handleInputChange} />
              ) : (
                <div  id="detail">{userDetails.email}</div>
              )}</div>
           <div className="ehb5">  
           <label>Phone</label>
              {editMode ? (
                <input name="phone" value={updatedUserDetails.phone} disabled />
              ) : (
                <div  id="detail">{userDetails.phone}</div>
              )}</div>
           <div className="ehb5">   <label>House No/Apartment No</label>
              {editMode ? (
                <input name="apartmentNo" value={updatedUserDetails.apartmentNo} onChange={handleInputChange} />
              ) : (
                <div  id="detail">{userDetails.apartmentNo}</div>
              )} </div>
           <div className="ehb10">   <label>Address</label>
              {editMode ? (
                <textarea name="address" value={updatedUserDetails.address} onChange={handleInputChange} />
              ) : (
                <div  id="detail">{userDetails.address}</div>
              )} </div>
           <div className="ehb10">
            
           <label>Postal Code</label>
              {editMode ? (
                <input name="pincode" value={updatedUserDetails.pincode} onChange={handleInputChange} />
              ) : (
                <div id="detail">{userDetails.pincode}</div>
              )}  </div>
            
            
             
            
            

              {editMode ? (
                <input name="lat" value={updatedUserDetails.lat} disabled hidden />
              ) : (
                <div></div>
              )}

              {editMode ? (
                <input name="long" value={updatedUserDetails.long} disabled hidden />
              ) : (
                <div></div>
              )}
              {editMode ? (
                <button onClick={handleSave} disabled={saving}>{saving ? "Saving..." : "Save"}</button>
              ) : (
                <button onClick={toggleEditMode}>Edit</button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyProfileComp;
