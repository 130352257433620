import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import DOMPurify from 'dompurify';
import moment from "moment-timezone";
import { Link, useNavigate } from 'react-router-dom';

const MyCart = () => {
  const [bookings, setBookings] = useState([]);
  const [userDetails, setuserDetails] = useState('');
  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();

    const fetchBookings = async () => {
      try {
        const response = await axios.get('https://ehb.easyhaibro.com/auth/v1/cart', {
          headers: {
            'Authorization': `Bearer ${userToken}` // Replace with your actual access token
          }
        });
        setBookings(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get('https://ehb.easyhaibro.com/auth/v1/user', {
        headers: {
          'Authorization': `Bearer ${userToken}`
        }
      });
      setuserDetails(response.data.data);
      console.log(response.data.data)
    } catch (error) {
      console.error(error);
    }
  };

 


  useEffect(() => {
    fetchUserDetails();
  fetchBookings();
}, [userToken]);
  const handleService = (value)=>{
    console.log(value)
    navigate(`/book-service/${value}?-----my-bookings`);
    window.scrollTo(0, 0);
  }

  const editMode = ()=>{

  }

 
  const handleSave = async () => {
    try {
      const response = await axios.patch(
        'https://ehb.easyhaibro.com/auth/v1/user',
        {
          address: [userDetails.address],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`,
          },
        }
      );

      setuserDetails(response.data.data);
  
      console.log('User details updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };
  
  const handleEdit = ()=>{

  }
  return (
    <>
      <Container fluid className="container category">
        <Tabs defaultActiveKey="myBookings" id="myTabs">
      

          <Tab eventKey="myBookings" title="My Cart">
          <div className="category-services">
            {bookings.map(booking => (
             




<div className="category-service-repeat mybookings">
    <div className="category-img">
        <img src={booking.service.serviceImage} />
    </div>
    <div className="category-details ">
        <h2>{booking.service.serviceName}</h2>
        <h3 id="price">₹ {booking.service.servicePrice}</h3>
        <p><span id="review">Service Status</span> <span id={booking.bookingStatus}>Cart
</span></p>
        <p><span id="review">Service Date</span> <span id="date">{moment(booking.pickUpDate).format("dd/mm/yyyy")}</span></p>
        <p><span id="review">Service Slot</span> <span id="date">{booking.serviceSlot}</span></p>
       <button onClick={()=>handleService(booking.service._id)}>Order Now</button>
      
    </div>
   
</div>




            ))}
            </div>
          </Tab>
       
          
        </Tabs>
      </Container>
    </>
  );
}

export default MyCart;
