
import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LoginModal from "../../modal/Login";
import Forum from "../../component/Forum";
import HowGrooming from "../../component/HowGroomingWorks";
import ServiceFaq from "../../component/ServiceFaq";
import Hyperlinks from "../../component/HyperLinksServices";
import NormalService from "../../component/NormalService";
import { ShimmerPostItem } from "react-shimmer-effects";
import CartButton from "../../component/CardButton";
const Category = (htmlContent) => {
  const [services, setServices] = useState([]);
  const [serviceId,setServiceId] = useState('');
  const userToken = localStorage.getItem("userToken");
  const { categoryName } = useParams();

  const [loading,setLoading]= useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const formattedCategory = categoryName
    .split('-')
    .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
    .join('');


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      console.log(formattedCategory)
      try {
        const response = await axios.get(`https://ehb.easyhaibro.com/auth/v1/services/${formattedCategory}`, {
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
        });
        if (response.status === 200) {
          setLoading(false);
          setServices(response.data.data);
        }

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleBookNow = (serviceId) => {
    window.location.href = `/book-service/${serviceId}`;

  };
  let redirectUrl = `/book-service/${serviceId}`;

  const handleButtonClick = (service) => {
    setServiceId(service._id)
    const isLoggedIn = userToken;
    if (isLoggedIn) {
      handleBookNow(service._id);
    } else {
      handleOpenModal(service._id)

    }
  };
  const placeholderItems = Array.from({ length: 4 });
  return (
    <>
      <Container fluid className="container category">

      <Hyperlinks />

        <div className="category-services">
        {loading &&
      <div className="category-shimmer">
{placeholderItems.map((_, index) => (
        <ShimmerPostItem
          key={index} // Make sure to provide a unique key
          card
          title
          cta
          imageType="thumbnail"
          imageWidth={50}
          imageHeight={50}
          contentCenter
        />
      ))}
      </div>
     }
          {services.map((service,index) => (
            <>
            {service.petType === "cat" &&
             <NormalService key={service.id} service={service} handleButtonClick={handleButtonClick} categoryName={categoryName} />
}
            </>
          ))}
        </div>

        <div className="category-services">
        {services.map((service, index) => (
            <NormalService key={service.id} service={service} handleButtonClick={handleButtonClick} categoryName={categoryName} />
          ))}
        </div>



        {categoryName === 'pet-grooming' &&
      <HowGrooming />
}

<Forum />
<ServiceFaq />




      </Container>
      <LoginModal show={showModal} handleClose={handleCloseModal}  handleLogin={handleButtonClick} redirectUrl={redirectUrl} />
     
    </>
  );
}

export default Category;
