import React, { useState } from 'react';

const FilterComponent = ({ onFilter }) => {
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState('');
  const [breed, setBreed] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [petType, setPetType] = useState('');

  const handleFilter = () => {

    const filters = {
      location,
      breed,
      gender,
      age,
      startDate,
      endDate,
      petType
    };
    onFilter(filters);
  };

  const priceOptions = [
    { label: 'Select Price Range', value: '' },
    { label: 'Less than 10,000', value: '<10000' },
    { label: '10,000 - 20,000', value: '10000-20000' },
    { label: '20,000 - 30,000', value: '20000-30000' },
    { label: '30,000 - 40,000', value: '30000-40000' },
    { label: '40,000 - 50,000', value: '40000-50000' },
    { label: '50,000 - 60,000', value: '50000-60000' },
    { label: '60,000 - 70,000', value: '60000-70000' },
    { label: '70,000 - 80,000', value: '70000-80000' },
    { label: '80,000 - 90,000', value: '80000-90000' },
    { label: '90,000 - 1 Lakh', value: '90000-100000' },
    { label: 'More than 1 Lakh', value: '>100000' }
  ];
  const locations = [
    { label: 'Select Location', value: '' },
    { label: 'Mumbai', value: 'mumbai' },
    { label: 'Bengalru', value: 'bengalru' },
   
  ]
  const petTypes = [
    { label: 'Select Pet Type', value: '' },
    { label: 'Dog', value: 'dog' },
    { label: 'Cat', value: 'cat' },
  ]
const petGender = [
  { label: 'Select Pet Gender', value: '' },
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
]
const petAge = [
  { label: 'Select Pet Age', value: '' },
  { label: '1 month', value: '1' },
  { label: '2 months', value: '2' },
  { label: '3 months', value: '3' },
  { label: '4 months', value: '4' },
  { label: '5 months', value: '5' },
  { label: '6 months', value: '6' },
  { label: '7 months', value: '7' },
  { label: '8 months', value: '8' },
  { label: '9 months', value: '9' },
  { label: '10 months', value: '10' },
  { label: '11 months', value: '11' },
  { label: '1 year', value: '12' },
  { label: '2 years', value: '24' },
  { label: '3 years', value: '36' },
  { label: '4 years', value: '48' },
  { label: '5 years', value: '60' },
  { label: '6 years', value: '72' },
  { label: '7 years', value: '84' },
  { label: '8 years', value: '96' },
  { label: '9 years', value: '108' },
  { label: '10 years', value: '120' },
  { label: '11 years', value: '132' },
  { label: '12 years', value: '144' },
  { label: '13 years', value: '156' },
  { label: '14 years', value: '168' },
  { label: '15 years', value: '180' },
  { label: '16 years', value: '192' },
  { label: '17 years', value: '204' },
  { label: '18 years', value: '216' },
  { label: '19 years', value: '228' },
  { label: '20 years', value: '240' },
];


  return (
    <div className='filter'>
      <h2>Filter</h2>
      <label>
        Location
        <select value={location} onChange={(e) => setLocation(e.target.value)}>
          {locations.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>
      {/* <label>
        Price
        <select value={price} onChange={(e) => setPrice(e.target.value)}>
          {priceOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </label> */}
      <label>
        Pet Type
        <select value={petType} onChange={(e) => setPetType(e.target.value)}>
        {petTypes.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
       
      </label>
      
      <label>
        Gender 
        <select value={gender} onChange={(e) => setGender(e.target.value)}>
        {petGender.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>
      <label>
        Age (1 to 14):
        <select value={age} onChange={(e) => setAge(e.target.value)}>
        {petAge.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>

      <button onClick={handleFilter}>Search</button>
    </div>
  );
};

export default FilterComponent;
