import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import PetAdoptionComp from "../../../component/Service";
import axios from 'axios';
import Banner from "../../../assets/media/Banner.png"
import { ShimmerPostItem } from "react-shimmer-effects";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Dog from '../../../assets/media/Dog.svg';
import Cat from '../../../assets/media/Cat.png';
import Other from '../../../assets/media/Small.svg';
import { useNavigate } from 'react-router-dom';
import FilterComponent from "../../../component/FilterComponent";
const PetAdoption = () => {
  const [petData, setPetData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [loading,setLoading]  = useState(false);
  const [catloading,setCatLoading]  = useState(false);
  const [filteredData, setFilteredData] = useState([]); 
  const navigate = useNavigate();


  useEffect(() => {
    getData();
    catDataS();
  }, []);

  const getData=(queryParams)=>{
   
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}adoption?location=&breed=&gender=&age=&startDate=&endDate=&petType=dog`,
      headers: { }
    };

    axios.request(config)
    .then((response) => {
    
     if(response.data.status === true){
      setPetData(response.data.data);
    
      setFilteredData()
     }
     if(response.data.status === false){
      setFilteredData(response.data.message);
     
     }
     
    })
    .catch((error) => {
      console.log(error);
    });

  }

  const catDataS=(queryParams)=>{
    setCatLoading(true)
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}adoption?location=&breed=&gender=&age=&startDate=&endDate=&petType=cat`,
      headers: { }
    };

    axios.request(config)
    .then((response) => {
    
     if(response.data.status === true){
      setCatData(response.data.data);
      setCatLoading(false);
      setFilteredData()
     }
     if(response.data.status === false){
      setFilteredData(response.data.message);
      setCatLoading(false)
     }
     
    })
    .catch((error) => {
      console.log(error);
    });

  }




  const placeholderItems = Array.from({ length: 4 });
  const handleFilter = (filters) => {
    const queryParams = new URLSearchParams(filters).toString();
    getData(queryParams);
    console.log( filters);
  };


  const handlePetType = (val)=>{
    if(val==='dog'){
      navigate('/pet-type/dog');
    }
    else if(val==='cat'){
      navigate('/pet-type/cat');
    }
    else{
      navigate('/pet-type/other');
    }
    
  }




  return (
    <Container>

<Row>
  <Col lg={12} md={12} sm={12}>
  <Carousel>

<Carousel.Item>
<img
 id="carousal"
    src={Banner} 
    alt=""
  />
  <Carousel.Caption>
  
  </Carousel.Caption>
</Carousel.Item>

</Carousel>
     
  </Col>
</Row>
    <div className="top"/>
   


    <Container fluid className="container lg-ds">
      <Row>
        <Col lg={12} md={12} sm={12}>
           <div className="services service-head">
             <h2>Are you</h2>
             <p>Looking for pet?</p>
             <h4 id="hide">Looking for pet</h4>
           </div>
           <div className="services-dv-new">
           <div className="services-list">
            <span onClick={()=>handlePetType('dog')}>
                  <img src={Dog} />
            </span>
               
                </div>
                <div className="services-list">
                <span onClick={()=>handlePetType('cat')}>
                  <img src={Cat} />
            </span>
                
                </div>
                <div className="services-list">
                <span onClick={()=>handlePetType('other')}>
                  <img src={Other} />
            </span>
                
                </div>
           </div>
       
        </Col>

        
      
      </Row>
    </Container>


    <Container fluid className="container lg-ds">
      <Row>
        <Col lg={12} md={12} sm={12}>
           <div className="services service-head">
             <h2>The most</h2>
             <p>Popular Dog Services</p>
             <h4 id="hide">Popular Dog Services</h4>
           </div>
      
           <div className="services-dv-four">
        

            </div>
           
        </Col>

      </Row>
    </Container>

    {(!loading && filteredData) &&
 <>
  <div className="ehb-row">
    <div className="ehb5">
      <img src="https://ehb.easyhaibro.com/auth/v1/media/?file=1714835715741.png" />
    </div>
    <div className="ehb5 ehb-404">
      <h2>404 Pet Not Found 🥺</h2>
      <p>Dog/Cat are not our whole life, but they make our lives whole</p>
    </div>
  </div>
  </>
}
      <div className="adoption">

        
      {loading &&
      <div className="category-shimmer">

{placeholderItems.map((_, index) => (
        <ShimmerPostItem
          key={index} // Make sure to provide a unique key
          card
          title
          cta
          imageType="thumbnail"
          imageWidth={50}
          imageHeight={50}
          contentCenter
        />
      ))}
  
      </div>
     }
     
     {(!loading && !filteredData) &&
     <>
      {petData.map((pet, index) => (
          <PetAdoptionComp
            key={index}
            petImage={pet.serviceImage}
            priceRange={`${pet.servicePrice}`}
            petName={pet.serviceName}
            address={pet.petBreed}
            gender={pet.petGender}
            btnName="Whatsapp" 
            serviceId={pet._id}
          />
        ))}
     </>
}

<Container fluid className="container lg-ds">
      <Row>
        <Col lg={12} md={12} sm={12}>
           <div className="services service-head">
             <h2>The most</h2>
             <p>Popular Cat Services</p>
             <h4 id="hide">Popular Cat Services</h4>
           </div>
      
           <div className="services-dv-four">
        

            </div>
           
        </Col>

      </Row>
    </Container>
    {loading &&
      <div className="category-shimmer">
    {placeholderItems.map((_, index) => (
        <ShimmerPostItem
          key={index} // Make sure to provide a unique key
          card
          title
          cta
          imageType="thumbnail"
          imageWidth={50}
          imageHeight={50}
          contentCenter
        />
      ))}
  
      </div>
     }
     
     {(!loading && !filteredData) &&
     <>
      {catData.map((pet, index) => (
          <PetAdoptionComp
            key={index}
            petImage={pet.serviceImage}
            priceRange={`${pet.servicePrice}`}
            petName={pet.serviceName}
            address={pet.petBreed}
            gender={pet.petGender}
            btnName="Whatsapp" 
            serviceId={pet._id}
          />
        ))}
     </>
}



      </div>
      {/* <Forum /> */}
    </Container>
  );
}

export default PetAdoption;
