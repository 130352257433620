import React from 'react';
import MyProfileComp from '../../../component/MyProfile';

const LoginDetails = () => {
  return (
    <div className='inner-section-checkout'>
    
    <MyProfileComp />
    </div>
  );
};

export default LoginDetails;
