import React, { useState } from 'react';
import { RiCoupon2Fill } from "react-icons/ri";
import axios from 'axios';

const CouponCode = () => {
  const [coupon, setCoupon] = useState('');
  const [discount, setDiscount] = useState(null);
  const [message, setMessage] = useState('');

  const handleApplyCoupon = async () => {
    let data = JSON.stringify({ code: coupon });

    let config = {
      method: 'post',
      url: 'http://localhost:3001/auth/v1/coupon-apply',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjY0OGI3Y2FmYmU3NWIyMzVhZTkyY2E3In0sImlhdCI6MTcxNjA0MTY3NH0.qsBTwRiHruCDhEptL0qYhY6SvRtMOilDeEJYJDoChgE'
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      if (response.data.success) {
        setDiscount(response.data.discount);
        setMessage(`Coupon applied! You saved ${response.data.discount}%.`);
      } else {
        setDiscount(null);
        setMessage('Invalid coupon code.');
      }
    } catch (error) {
      console.error(error);
      setDiscount(null);
      setMessage('An error occurred while applying the coupon.');
    }
  };

  return (
    <div className="payment-summay">
      <h2><RiCoupon2Fill /> Coupons & Offers</h2>
      <div className='coupon-code-container'>
        <input
          type="text"
          value={coupon}
          onChange={(e) => setCoupon(e.target.value)}
          placeholder="Enter coupon code"
        />
        <button onClick={handleApplyCoupon}>Apply</button>
      </div>
      {message && <p>{message}</p>}
      {discount !== null && (
        <div className="discount">
          <p>Discount: {discount}%</p>
        </div>
      )}
    </div>
  );
};

export default CouponCode;
