import React, { useState, useEffect } from "react";
import { CiSquarePlus } from "react-icons/ci";
import axios from 'axios';
import { IoIosInformationCircle } from "react-icons/io";
import { CiBookmarkCheck } from "react-icons/ci";
import { FaClipboardList } from "react-icons/fa";
import { ShimmerPostItem } from "react-shimmer-effects";
import PetAdoptionComp from '../../../component/Service';

function MyPets() {
    const [petData, setPetData] = useState([]);
    const [loading, setLoading] = useState(true);
  

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}my-pets`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        };

        axios.request(config)
            .then((response) => {
                setPetData(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
      
    }, [petData]); // Whenever petData changes, mypet might also change

    return (
        <div className='container topContainer'>
            <div className='ehb-row'>
                <div className='ehb3'>
                    <div className='section-left'>
                        <a href="/adoption">
                            <div className='non active-section'>
                                <CiSquarePlus id="plus" />{`  `} Add Adoption Pet
                            </div>
                        </a>
                        <a href="/my-pets">
                            <div className='active-section'>
                                <FaClipboardList id="plus" />{`  `} My Pet Lists
                            </div>
                        </a>
                        {/* <a href="/pet-bookings">
                            <div className='non active-section'>
                                <CiBookmarkCheck id="plus" />{`  `} Adoption Pet Bookings
                            </div>
                        </a> */}
                    </div>
                </div>
                <div className='ehb7'>
                    <div className='section-left'>
                        <div className='ehb-column'>
                            <div className='ehb10 ehb-main'>
                                <h2>Your Pet List For Adoption</h2>
                                {loading ? (
                                    <div className="adoption">
                                        <ShimmerPostItem card title text cta />
                                        <ShimmerPostItem card title text cta />
                                    </div>
                                ) : (
                                    <div className="adoption">
                                        {petData.length === 0 ? (
                                            <div>No service found</div>
                                        ) : (
                                            petData.map((pet, index) => (
                                                <PetAdoptionComp
                                                    key={index}
                                                    petImage={pet.serviceImage}
                                                    priceRange={`${pet.servicePrice}`}
                                                    petName={pet.serviceName}
                                                    petType={pet.petType}
                                                    address={pet.petBreed}
                                                    gender={pet.petGender}
                                                    serviceId={pet._id}
                                                    mypet={pet.listingStatus}
                                                    viewDetails="View/Edit"
                                                   
                                                />
                                            ))
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyPets;
