import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import InputMask from 'react-input-mask';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import moment from 'moment-timezone';
import CartButton from './CardButton';

const MySwal = withReactContent(Swal);

const BookingForm = ({
  serviceData,
  address,
  setAddress,
  pincode,
  setPincode,
  houseNo,
  setHouseNo,
  aprtmentNo,
  setApartmentNo,
  dropOffDate,
  setDropOffDate,
  pickupDate,
  setPickupDate,
  selectedDate,
  setSelectedDate,
  selectedSlot,
  setSelectedSlot,
  serviceSlots,
  handleCart,
  handlePayment
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const isMumbaiArea = results[0].address_components.some((component) =>
        component.types.includes('locality') && (component.long_name === 'Mumbai' || component.long_name === 'Navi Mumbai' || component.short_name === 'NM' || component.long_name === 'Thane')
      );
      if (isMumbaiArea) {
        setAddress(selectedAddress);
      } else {
        await MySwal.fire({
          title: "",
          text: "Sorry! currently we are not serving in your area.",
          iconHtml: '😟',
        });
      }
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const validatePincode = (value) => {
    const allowedPatterns = ['4*', '5*'];
    for (const pattern of allowedPatterns) {
      const regex = new RegExp('^' + pattern.replace(/\*/g, '\\d*') + '$');
      if (regex.test(value)) {
        return true;
      }
    }
    return false;
  };

  const handlePincodeChange = async (event) => {
    const pincode = event.target.value;
    if (pincode.length <= 5) {
      setPincode(pincode);
    } else {
      if (validatePincode(pincode)) {
        setPincode(pincode);
      } else {
        await MySwal.fire({
          title: "",
          text: "Sorry! Currently, we are not serving in your area.",
          iconHtml: '😟',
        });
        setPincode('');
      }
    }
  };

  const handleValueChange = (newValue) => {
    setTotalItems(newValue);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
              <h2>Book a service!</h2>
            <CartButton onValueChange={handleValueChange} />
          </div>
        );
      case 2:
        return (
          <div>
            <h2>Select a Service Date and Slot</h2>
            <div className="dateSlot">
              {serviceSlots.length > 0 ? (
                serviceSlots.map((data, index) => (
                  <p
                    id="dateSlot"
                    key={index}
                    onClick={() => setSelectedDate(data.date)}
                    className={selectedDate === data.date ? 'selectedDate' : ''}
                  >
                    {moment(data.date).format('dd DD')}
                  </p>
                ))
              ) : (
                <p>Not Available</p>
              )}
            </div>
            {selectedDate && (
              <div>
                <h2 id="slot">Select a service slot for {moment(selectedDate).format('DD MMMM')}</h2>
                <ul className="avSlot">
                  {serviceSlots
                    .find((slotData) => moment(slotData.date).isSame(selectedDate, 'day'))
                    ?.availableSlots?.length > 0 ? (
                    serviceSlots
                      .find((slotData) => moment(slotData.date).isSame(selectedDate, 'day'))
                      .availableSlots.map((slot, slotIndex) => (
                        <li
                          key={slotIndex}
                          id={slot.booked ? 'avSlotBooked' : 'avSlot'}
                          className={selectedSlot === slot ? 'selectedDate' : ''}
                          onClick={() => setSelectedSlot(slot)}
                        >
                          {slot.time}
                        </li>
                      ))
                  ) : (
                    <p id="avSlotNotAvailable">Not Available Slots</p>
                  )}
                </ul>
              </div>
            )}
          </div>
        );
      case 3:
        return (
          <div>
            <h2>Enter Address Details</h2>
            <div className="booking-label">
              <label>House/Flat/Block NO.*</label>
              <input type="text" value={houseNo} onChange={(e) => setHouseNo(e.target.value)} />
            </div>
            <div className="booking-label">
              <label>Apartment/Road/Area*</label>
              <input type="text" value={aprtmentNo} onChange={(e) => setApartmentNo(e.target.value)} />
            </div>
            <div className="booking-label">
              <label>Current Location*</label>
              <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <input {...getInputProps({ placeholder: 'Type Your Current Address...', className: 'location-search-input' })} />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const style = { backgroundColor: suggestion.active ? '#41b6e6' : '#fff' };
                        return (
                          <div {...getSuggestionItemProps(suggestion, { style })}>
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <div className="booking-label">
              <label>Pincode*</label>
              <InputMask mask="999999" maskChar="" value={pincode} onChange={handlePincodeChange} placeholder="Enter Pincode" />
            </div>
          </div>
        );
      case 4:
        return (
          <div className='billing-details'>
            <h2>Billing details</h2>
            <p><strong>Service Date:</strong> {selectedDate ? moment(selectedDate).format('DD MMMM') : 'Not selected'}</p>
            <p><strong>Service Slot:</strong> {selectedSlot ? selectedSlot.time : 'Not selected'}</p>
            <p><strong>Address:</strong> {address}</p>
            <p><strong>Pincode:</strong> {pincode}</p>
          
            {serviceData.categoryName !== 'petGrooming' && serviceData.categoryName !== 'dogWalk' && (
              <div className="booking-label">
                <label>Drop Off Date*</label>
                <input type="date" min={pickupDate || new Date().toISOString().split('T')[0]} value={dropOffDate} onChange={(e) => setDropOffDate(e.target.value)} />
              </div>
            )}
            {serviceData && address && pincode ? (
              <div className="booking-label btn-two">
                <input type="submit" id="submit" value="Proceed to pay" onClick={() => handlePayment(serviceData)} />
              </div>
            ) : (
              <div className="booking-label btn-two">
                <input type="submit" id="disable" value="Add to cart" disabled />
                <input type="submit" id="disable" value="Order now" disabled />
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="booking-details">
   
      {renderStep()}
      <div className="navigation-buttons">
        {currentStep > 1 && (
          <button onClick={() => setCurrentStep(currentStep - 1)} id="sec">Previous</button>
        )}
        {currentStep < 4 && (
          <button onClick={() => setCurrentStep(currentStep + 1)}>Next</button>
        )}
      </div>
    </div>
  );
};

export default BookingForm;
