import React from 'react';
import { MdOutlinePayment } from "react-icons/md";

const PaymentSummary = () => {
  return (
    <div className='inner-section-checkout'>
     <div className='payment-summay'>
         <h2><MdOutlinePayment /> Payment summary</h2>
         <table>
            <tr>
                <th>Item total</th>
                <td>1</td>
            </tr>
            <tr>
                <th>Item discount</th>
                <td>₹ 100</td>
            </tr>
            <tr>
                <th>Taxes and Fee</th>
                <td>₹ 99</td>
            </tr>
            <tr>
                <th>Express Fee</th>
                <td>₹ 0</td>
            </tr>
            <tr id="total">
                <th>Amount to pay</th>
                <td>₹ 199</td>
            </tr>
            <button id="final-btn">Proceed to continue</button>
         </table>
     </div>
    
    </div>
  );
};

export default PaymentSummary;
