import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { useParams } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios';
import {  useNavigate } from "react-router-dom";
import DOMPurify from 'dompurify';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginModal from "../../modal/Login";
import Not from '../../assets/media/4040.svg';
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { HiOutlineCash } from "react-icons/hi";
import { GrUserExpert } from "react-icons/gr";
import { GiTransparentSlime } from "react-icons/gi";
import ReviewsPetGrooming from "./ReviewPetGrooming";
import ReviewsPetTraining from "./ReviewPetTraining";
import ReviewDogWalk from "./ReviewDogWalk";
import ReviewVetHome from "./ReviewVetHome";

const SearchPage = (htmlContent) => {
    const [services, setServices] = useState([]);
    const [serviceId,setServiceId] = useState('');
    const userToken = localStorage.getItem("userToken");
    const { keyword } = useParams();
    const [show, setShow] = useState(false);
    const[modalData,setModalData] = useState(false);
    const [loading,setLoading]= useState(false);
    const [showModal, setShowModal] = useState(false);



  const handleButtonClick = (service) => {
    setServiceId(service._id)
    const isLoggedIn = userToken;
    if (isLoggedIn) {
      handleBookNow(service._id);
    } else {
      handleOpenModal(service._id)
      
    }
  };
  const handleBookNow = (serviceId) => {
    window.location.href = `/book-service/${serviceId}`;
   
  };
  let redirectUrl = `/book-service/${serviceId}`;

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
        
          try {
            const response = await axios.get(`${process.env.REACT_APP_HOST }search/?keyword=${keyword}`, {
              headers: {
                'Authorization': `Bearer ${userToken}`,
              },
            });
            console.log(response.data)
            if (response.status === 200) {
              setLoading(false);
              setServices(response.data.data);
            }
           
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchData();
      }, []);

  return (
    <>
      <Container fluid className="container category">
        <div className="hyperlinks">
          <Link to="/">Home</Link> / search / <span id="activeLink">{keyword}</span>
          <h2>Search Result For : {keyword}</h2>
          <p>Service that fits your budget with quality assurance!</p>
          {services.length>0 &&
          <div className="ehb-prtotection">
             <div className="ehb-prt-div one">
             <VscWorkspaceTrusted />

              <div className="ehb-menu">
                <h2>100% Satisfaction Guaranteed:</h2>
                <p>If you're not satisfied, we offer a full refund to ensure your peace of mind</p>
              </div>
             
             </div>
             <div className="ehb-prt-div two">
             <HiOutlineCash />
              <div className="ehb-menu">
                <h2>Cash Payment Available</h2>
                <p>Conveniently pay in cash for our services, providing you with flexibility in payment options.</p>
              </div>
             
             </div>
             <div className="ehb-prt-div three">
             <GrUserExpert />

              <div className="ehb-menu">
                <h2>Expert Care Professionals:</h2>
                <p>Our passionate team of experienced professionals ensures the highest standard of care for your pets.</p>
              </div>
             
             </div>

             <div className="ehb-prt-div three">
             <GiTransparentSlime />

              <div className="ehb-menu">
                <h2>Transparent and Flexible:</h2>
                <p>Open communication and fair, straightforward policies for a positive experience.</p>
              </div>
             
             </div>
          </div>
}
        </div>
        

        <div className="category-services">
          {services.map((service,index) => (
            <div key={service.id} className={`category-service-repeat ${index == 1 && 'recom'}`}>
              {index == 1 &&
              
               <div className="recommended">
                <p>RECOMMENDED</p>
               </div> 

              }
              <div className="category-img">
                <img src={service.serviceImage} alt={`Service Image for ${service.name}`} />
                <h2>{service.serviceName}</h2>
              </div>
              <div className="category-details">
               
                <p id="review-div">
                  <span id="review">{service.reviews} Reviews</span>
                  <span id="number">{` 9.3`} </span>
                </p>
                <h3 id="price">₹ {service.servicePrice}/<span id="permonth">{service.serviceText}</span></h3>
               
               
                <div className="addto">
              
                <button id="add" onClick={()=>handleButtonClick(service)}>
                   Book now 
                </button>

                </div>
                <div className="service-desc">
                <div dangerouslySetInnerHTML={{ __html: service.serviceDescription }} />
                </div>
              </div>
            </div>
          ))}
          {services>=0 &&
          <>
           <h2>Oh Sorry!, No Service Found</h2>
          <img src={Not} id="404"/>
          </>
         
          }
        </div>


    </Container>
    <LoginModal show={showModal} handleClose={handleCloseModal}  handleLogin={handleButtonClick} redirectUrl={redirectUrl} />
      {loading &&
      <div class="kinetic"></div>
     }

    </>
  );
}

export default SearchPage;
