import { React } from "react";
import CarouselComponent from '../../component/CarouselComponent';
import HowItWorksComponent from '../../component/HowItWorksComponent';
import ReviewsSection from '../../component/ReviewsSection';
import FAQComponent from '../../component/FAQComponent';
import ServiceComponents from '../../component/ServiceComponents';
function Homescreen() { 

  return (
    <>
    
  <CarouselComponent />
  <ServiceComponents  />
  <HowItWorksComponent />
  <ReviewsSection />
  <FAQComponent />      
    </>
  );
}

export default Homescreen;
