import React, { useRef, useEffect } from 'react';
import Vimeo from '@vimeo/player';

const VimeoPlayer = ({ videoId }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    // Create a new Vimeo Player instance
    const player = new Vimeo(playerRef.current, {
      id: videoId,
    
    });

    return () => {
      // Clean up when the component is unmounted
      player.destroy();
    };
  }, [videoId]);

  return (
    <div>
    
      <div ref={playerRef} id="vimeo-class"></div>
    </div>
  );
};

export default VimeoPlayer;
