

const host = 'https://ehb.easyhaibro.com/auth/v1/';

const API_ENDPOINT = {
    
    categories: `${host}category`,

  };
  
  export default API_ENDPOINT;