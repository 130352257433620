import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PaymentOptions = ({ bookingId }) => {
  const [paymentOption, setPaymentOption] = useState('payNow');

  const handlePaymentOptionChange = async (option) => {
    try {
      const response = await axios.post('http://localhost:3001/auth/v2/booking', {
        bookingId: '664a0cd3b928eb7238a69406',
        paymentOption: option
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      
      console.log(response.data);
      // Handle success, e.g., show success message
    } catch (error) {
      console.error('Error updating payment option:', error);
      // Handle error, e.g., show error message
    }
  };

  useEffect(() => {
    handlePaymentOptionChange(paymentOption);
  }, [paymentOption]);

  return (
    <div className="inner-section-checkout">
      <div className='slots'>
        <div className="payment-option">
          <input
            type="radio"
            id="payNow"
            name="paymentMethod"
            value="payNow"
            checked={paymentOption === 'payNow'}
            onChange={() => setPaymentOption('payNow')}
          />
          <label htmlFor="payNow">Pay Now</label>
        </div>
        <div className="payment-option">
          <input
            type="radio"
            id="payAfterService"
            name="paymentMethod"
            value="payAfterService"
            checked={paymentOption === 'payAfterService'}
            onChange={() => setPaymentOption('payAfterService')}
          />
          <label htmlFor="payAfterService">Pay After Service</label>
        </div>
      </div>
    </div>
  );
};

export default PaymentOptions;
