import React from 'react';
import ReactStars from 'react-stars'

const Forum = () => {
    const ratingChanged = (newRating) => {
        console.log(newRating)
      }
   

  return (
    <div className="ehb-forum">
        <h2>Leave a Review*</h2>
        <p>Your email address will not be published. Required fields are marked *</p>
        <div className='label-input'>
            <label>Ratings*</label>
        <ReactStars count={5}
  onChange={ratingChanged}
  size={24}
  color2={'#ffd700'} />
        </div>
        <div className='label-input'>
            <label>Write your experience *</label>
            <textarea />
        </div>
        <div className='label-input btn-form'>
           <button>Publish Review</button>
        </div>
      
    </div>
  );
};

export default Forum;
