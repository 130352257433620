import React,{useState,useEffect} from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { ShimmerText } from "react-shimmer-effects";
const Slots = () => {
  const [serviceSlots, setServiceSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState('');
  const [serviceDate, setServiceDate] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [serviceData, setServiceData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://ehb.easyhaibro.com/auth/v1/get-slot');
        const data = response.data;
        const slotsForDate = data.slots;
        setServiceSlots(response.data);
        setServiceDate(data.date);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching service slots:', error);
      }
    };

    fetchData();
  }, []); 
  return (
    <div className='inner-section-checkout'>
       <div className='slots'> 
            <div className="dateSlot">
              {serviceSlots.length > 0 ? (
                serviceSlots.map((data, index) => (
                  <p
                    id="dateSlot"
                    key={index}
                    onClick={() => setSelectedDate(data.date)}
                    className={selectedDate === data.date ? 'selectedDate' : ''}
                  >
                    {moment(data.date).format('dd DD')}
                  </p>
                ))
              ) : (
               <p>Loading...</p>
              )}
            </div>
            {selectedDate && (
              <div>
                <h2 id="slot">Select a service slot for {moment(selectedDate).format('DD MMMM')}</h2>
                <ul className="avSlot">
                  {serviceSlots
                    .find((slotData) => moment(slotData.date).isSame(selectedDate, 'day'))
                    ?.availableSlots?.length > 0 ? (
                    serviceSlots
                      .find((slotData) => moment(slotData.date).isSame(selectedDate, 'day'))
                      .availableSlots.map((slot, slotIndex) => (
                        <li
                          key={slotIndex}
                          id={slot.booked ? 'avSlotBooked' : 'avSlot'}
                          className={selectedSlot === slot ? 'selectedDate' : ''}
                          onClick={() => setSelectedSlot(slot)}
                        >
                          {slot.time}
                        </li>
                      ))
                  ) : (
                    <p id="avSlotNotAvailable">Not Available Slots</p>
                  )}
                </ul>
              </div>
            )}
          </div>
    
    </div>
  );
};

export default Slots;
