import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import MyBookingsComp from "../../component/MyBookings";
import MyProfileComp from "../../component/MyProfile";
import { useParams } from "react-router-dom";

const MyBookings = () => {
  const { tab } = useParams(); // Get the value of the "tab" parameter from the URL

  return (
    <>
      <Container fluid className="container category">
        <Tabs defaultActiveKey={tab || "myBookings"} id="myTabs"> 
          <Tab eventKey="myProfile" title="My Profile">
            <MyProfileComp />
          </Tab>
          <Tab eventKey="myBookings" title="My Bookings">
            <MyBookingsComp />
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}

export default MyBookings;
