import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from "react-router-dom";
import PhoneInput, { PhoneNumber } from 'react-phone-number-input';
import OTPInput from "otp-input-react";

import axios from "axios";

function LoginModal({ show, handleClose, handleLogin ,redirectUrl}) {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [hide, setHide] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const [resendDisabled, setResendDisabled] = useState(true); // Initially disabled
  const [otpVerified, setOtpVerified] = useState(false);
  const [resendClicked, setResendClicked] = useState(false); // Flag to track if resend button is clicked

const handleSendOtp =()=>{

  const qs = require('qs');
let data = qs.stringify({
  'To': `+91${phoneNumber}`,
  'Channel': 'sms' 
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://verify.twilio.com/v2/Services/VAf74968ce30586ad48fb747acd2a78891/Verifications',
  headers: { 
    'Content-Type': 'application/x-www-form-urlencoded', 
    'Authorization': 'Basic QUNlYWVhMWU4ZWRlYmEwN2QyMjk3ZDhjODVjZmY0YjM0NzowYzQ0MGNmOGU3MTNhNGI0ODZhYWQ3M2I3MjA3YjRjMA=='
  },
  data : data
};

axios.request(config)
.then((response) => {
  setHide(true);

})
.catch((error) => {
  console.log(error);
});

}
const startResendTimer = () => {
  setResendDisabled(true); // Disable the resend button
  const timerInterval = setInterval(() => {
    setResendTimer(prevTimer => {
      if (prevTimer === 1) {
        clearInterval(timerInterval);
        setResendDisabled(false); // Enable the resend button
        return 30; // Reset timer value
      } else {
        return prevTimer - 1;
      }
    });
  }, 1000);
};

const veirfyOtp =()=>{
  const qs = require('qs');
  let data = qs.stringify({
    'To': `+91${phoneNumber}`,
    'Code':otp 
  });
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://verify.twilio.com/v2/Services/VAf74968ce30586ad48fb747acd2a78891/VerificationCheck',
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded', 
      'Authorization': 'Basic QUNlYWVhMWU4ZWRlYmEwN2QyMjk3ZDhjODVjZmY0YjM0NzowYzQ0MGNmOGU3MTNhNGI0ODZhYWQ3M2I3MjA3YjRjMA=='
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
  
    if(response.data.status === 'approved'){
      let data = JSON.stringify({
        "phone": phoneNumber
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_HOST}verify-otp`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        localStorage.setItem("userToken", response.data.userToken);
        handleClose();
        navigate(redirectUrl);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
      
      
    }
  })
  .catch((error) => {
    console.log(error);
  });
  
}

useEffect(() => {
  let timer;
  if (hide) {
    timer = setInterval(() => {
      setResendTimer(prevTimer => {
        if (prevTimer === 1) {
          clearInterval(timer);
          setResendDisabled(false);
          return 30;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
  }
  return () => clearInterval(timer);
}, [hide]);
const handleResendOtp = () => {
  handleSendOtp();
};


  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Enter your details to continue!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="booking-label modal-phone">
        {hide === false && 
        <>
          <label>Phone Number</label>
          <input
          minLength={10}
  type='number'
  placeholder="Enter phone number"
  value={phoneNumber}
  onChange={(e) => setPhoneNumber(e.target.value)}
/>
<Button id="submit-phone" onClick={handleSendOtp}>
            Send OTP
          </Button>
</>
}
          {hide === true && 
       <>
          <div className="booking-label modal-phone">
            <label>Enter OTP</label>
            <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={5} otpType="number" />
            </div>
       


            <Button
                id="submit-phone"
                onClick={veirfyOtp}
                disabled={!otp || otp.length < 5 }
              >
                Verify OTP
              </Button>
          <Button
                id="resend"
                onClick={handleResendOtp}
               
              >
                Resend OTP 
              </Button>

          </>
}
        </div>
      </Modal.Body>

      <Modal.Footer>
     
      </Modal.Footer>
    </Modal>
  );
}

export default LoginModal;
