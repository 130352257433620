import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DOMPurify from 'dompurify';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { useParams } from "react-router-dom";
import {  useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';
import BookingForm from "../../component/BookingForm";
import { css } from '@emotion/react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const MySwal = withReactContent(Swal);
const BookService = () => {
  const [currentAddress, setCurrentAddress] = useState("");
  const [address, setAddress] = useState('');
  const [serviceTime, setServiceTime] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());
  const [pincode, setPincode] = useState("");
  const [totalItems, setItems] = useState(1);
  const [serviceSlots, setServiceSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState('');
  const [dropOffDate, setDropOffDate] = useState("");
  const [loading,setLoading]= useState(false);
  const [houseNo,setHouseNo] = useState("");
  const [aprtmentNo,setApartmentNo] = useState("");
  const [serviceDate, setServiceDate] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [serviceData, setServiceData] = useState({});
  const userToken = localStorage.getItem("userToken");
  const [nextDayDate, setNextDayDate] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [payment,setPayment] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const navigate = useNavigate();
  const url = window.location.href;
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  const sanitizedHtml = DOMPurify.sanitize(serviceData.serviceDescription);
  const [pickupDate, setPickupDate] = useState(selectedDate);
  const handleSelect = async (selectedAddress) => {
    try {
        const results = await geocodeByAddress(selectedAddress);
        const latLng = await getLatLng(results[0]);
        const isMumbaiArea = results[0].address_components.some((component) =>
            component.types.includes('locality') && (component.long_name === 'Mumbai' || component.long_name === 'Navi Mumbai' || component.short_name === 'NM' ||  component.long_name === 'Thane' )
        );
        if (isMumbaiArea) {
            setAddress(selectedAddress);
            console.log('Selected Address:', selectedAddress);
        } else {
            await MySwal.fire({
                title: "",
                text: "Sorry! currently we are not serving in your area.",
                iconHtml: '😟',
            });
        }
    } catch (error) {
        console.error('Error selecting address:', error);
    }
};



  
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
    setPickupDate(formattedDate);
  }, []);


  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await axios.get(`https://ehb.easyhaibro.com/auth/v1/service/${lastPart}`, {
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
        });

        setServiceData(response.data);
      } catch (error) {
        console.error("Error fetching service details:", error);
      }
    };

    fetchServiceDetails();
  }, [userToken]);

  const handleAddressChange = (event) => {
    setCurrentAddress(event.target.value);
  };


  const allowedPatterns = [
    '4*',
    '5*'
  ];
  
const validatePincode = (value) => {
  for (const pattern of allowedPatterns) {
    const regex = new RegExp('^' + pattern.replace(/\*/g, '\\d*') + '$');
    if (regex.test(value)) {
      return true;
    }
  }
  return false;
};

  const handlePincodeChange = async (event) => {
    const pincode = event.target.value;
  
    if (pincode.length <= 5) {
      setPincode(pincode);
    } else {
      if (validatePincode(pincode)) {
        setPincode(pincode);
      } else {
        await MySwal.fire({
          title: "",
          text: "Sorry! Currently, we are not serving in your area.",
          iconHtml: '😟',
        });
        setPincode();
      }
    }
  };
  
  


  const handleDropOffDateChange = (event) => {
    setDropOffDate(event.target.value);
  };
const handleApartmentChange = (event) => {
  setApartmentNo(event.target.value);
};
const handleHouseChange = (event) => {
  setHouseNo(event.target.value);
};
  const handleGoBack =()=>{
    navigate.goBack()
  }

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const containerStyle = {
    height: expanded ? 'auto' : 'auto',
    overflow: 'hidden',
    transition: 'height 0.3s ease-in-out 0s', 
    minHeight: "0px", 
    position: "relative",
  };
  
  const handlePayment = () =>{
    setPayment(true);
  }


  const { name } = useParams();
 

  const currentDate = new Date().toISOString().split('T')[0];

  const handleBookingSubmit = async (val) => {
    const data = JSON.stringify({
      "user_id": "1",
      "price": 31,
      "phone": 21121,
      "name": "212"
    });
  
    const config = {
      method: 'get', 
      maxBodyLength: Infinity,
      url: `https://ehb.easyhaibro.com/auth/v1/pay?amount=${val}`,
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${userToken}`
      },
      data: data
    };
  
    try {
        window.location.href = "https://ehb.easyhaibro.com/auth/v1/pay?amount=1";
     
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleCart = async () => {
    console.log(selectedDate,selectedSlot.time)

    try {
      setLoading(true)

      const response = await axios.post('https://ehb.easyhaibro.com/auth/v1/booking', {
        ServiceId: name,
        petType: "Dog",
        pickUpDate: selectedDate,
        dropOffDate: dropOffDate,
        petSize: "Medium",
        currentAddress: address,
        bookingStatus:'pending',
        serviceSlot:selectedSlot.time,
        houseNo:houseNo,
        apartmentNo:aprtmentNo,
        totalBooking:totalItems,
        orderIn:'cart'
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`,
        },
      });

      if (response.status === 201) {
        setLoading(false);
      
let data = JSON.stringify({
  "date":selectedDate,
  "time": selectedSlot.time
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://ehb.easyhaibro.com/admin/auth/v1/book-slot',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${userToken}`
  },
  data : data
};

axios.request(config)
.then((response) => {
 
})
.catch((error) => {
  console.log(error);
});

        await Swal.fire({
            title: "Added to cart",
            text: "Your booking has been added to cart successfully!",
            icon: "success",
          }); 
      } 
      
    } catch (error) {
      console.error("Error making booking:", error);
    }
  };




  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://ehb.easyhaibro.com/auth/v1/get-slot');
        const data = response.data;
        const slotsForDate = data.slots;
        setServiceSlots(response.data);
        setServiceDate(data.date);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching service slots:', error);
      }
    };

    fetchData();
  }, []); 
  const handleSlotClick = (slot) => {
   
    setSelectedSlot(slot);
  };


  const generateTimeSlots = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
  
    // Convert pickupDate to a Date object
    const pickupDateObj = new Date(pickupDate);
  
    // If pickupDate is the same as the current date, display slots from serviceSlots
    if (
      pickupDateObj.getDate() === currentDate.getDate() &&
      pickupDateObj.getMonth() === currentDate.getMonth() &&
      pickupDateObj.getFullYear() === currentDate.getFullYear()
    ) {
      return serviceSlots.map((slot, index) => (
        <option key={index} value={slot}>
          {slot}
        </option>
      ));
    } else {
      const defaultSlots = [];
      for (let hour = 9; hour <= 20; hour++) {
       
        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
        defaultSlots.push(
          <option key={hour} value={`${formattedHour}:00`}>
            {`${formattedHour}:00`}
          </option>
        );
      }
      
      return defaultSlots;
    }
  };
  
  function tomorrowDate(currentDate) {
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowISOString = tomorrow.toISOString().split('T')[0];
    return tomorrowISOString;
  }

  function todayDate(currentDate) {
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(tomorrow.getDate());
    const tomorrowISOString = tomorrow.toISOString().split('T')[0];
    return tomorrowISOString;
  }

  const handleDec =()=>{
    setItems(totalItems-1)
  }
  const handleInc =()=>{
    setItems(totalItems+1)
  }

  return (
    <>
      <Container fluid className="container category">
        <div className="hyperlinks">
          <a href="/" >Home </a> {` `}/
          <a onClick={handleGoBack} id="activeLink">{serviceData.categoryName}</a>
          <h2>{serviceData.categoryName}</h2>
        </div>

        <Container fluid className="container lg-ds">
          <Row className="orderd">
          <Col lg={6} md={12} sm={12}>
      <div className="category-service-repeat book" style={containerStyle} id="containerStyle">
        <div className="inside category-img">
          <img src={serviceData.serviceImage} alt="Service Thumbnail" />
        </div>
        <div className="category-details">
          <h3 id="price">₹ {serviceData.servicePrice}</h3>
          <p>
            <span id="review">{serviceData.reviews} Reviews</span>{' '}
            <span id="number">{`9.3`}</span>
          </p>
          <p id="descFull" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        </div>
        
      </div>
      <button onClick={handleToggleExpand} id="viewMore">
        {expanded ? 'View Less' : 'View More'}
      </button>
     
    </Col>
    <Col lg={6} md={12} sm={12}>
    <BookingForm
        serviceData={serviceData}
        address={address}
        setAddress={setAddress}
        pincode={pincode}
        setPincode={setPincode}
        houseNo={houseNo}
        setHouseNo={setHouseNo}
        aprtmentNo={aprtmentNo}
        setApartmentNo={setApartmentNo}
        dropOffDate={dropOffDate}
        setDropOffDate={setDropOffDate}
        pickupDate={pickupDate}
        setPickupDate={setPickupDate}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        serviceSlots={serviceSlots}
        handleCart={handleCart}
        handlePayment={handlePayment}
      />
       </Col>
          </Row>
        </Container>
      </Container>
     
     
     {loading &&
      <div class="kinetic"></div>
     }
    </>
  );
};

export default BookService;
