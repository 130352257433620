import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from '../assets/media/car.png';
import ExampleCarouselImage2 from '../assets/media/slider_2.svg';

const CarouselComponent = () => {
return(
<Container fluid className="container">
<Row>
  <Col lg={12} md={12} sm={12}>
  <Carousel>

<Carousel.Item>
<img
 id="carousal"
    src={ExampleCarouselImage} 
    alt=""
  />
  <Carousel.Caption>
  
  </Carousel.Caption>
</Carousel.Item>
<Carousel.Item>
<img
  id="carousal"
    src={ExampleCarouselImage2} 
    alt=""
  />
  <Carousel.Caption>
  </Carousel.Caption>
</Carousel.Item>
</Carousel>
     
  </Col>
</Row>
</Container>

);
};

export default CarouselComponent;