import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import {useCategories, handleService} from '../services/services';
import { useNavigate } from "react-router-dom";
const FAQComponent = ({ category, handleService }) => {

  const categories = useCategories();
  const navigate = useNavigate();
  const handleClick = (val) => {
    handleService(val, navigate);
  };
  return (
    <Container >
    <div className="faqs">
      <h2 id="faqsHead">Frequently Asked Questions.</h2>
      <Accordion defaultActiveKey="0" flush>


    <Accordion.Item eventKey="0">
      <Accordion.Header>How to book a service on Easy Hai Bro?</Accordion.Header>
      <Accordion.Body>
      Just click on the service required by you, see the prices and fill some basic contact details to schedule the service.


      </Accordion.Body>
    </Accordion.Item>


    <Accordion.Item eventKey="1">
      <Accordion.Header>Who is going to fulfill the service?</Accordion.Header>
      <Accordion.Body>
      We will assign an Easy Hai Bro Partner to complete your service at your preferred time slot.
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="2">
      <Accordion.Header>Who is an Easy Hai Bro?</Accordion.Header>
      <Accordion.Body>
      Easy Hai Bro Partners are selected individuals / companies who meet our stringent criteria for delivering quality services.
      </Accordion.Body>
    </Accordion.Item>

    <Accordion.Item eventKey="3">
      <Accordion.Header>How are Easy Hai Bro services different from other online portals?</Accordion.Header>
      <Accordion.Body>
      Other online portals merely put you in touch with the service providers leaving the service quality upto individual vendors. On the other hand, all Easy Hai Bro services are owned and fulfilled by Easy Hai Bro Partners who adhere to a strict quality criteria.
      </Accordion.Body>
    </Accordion.Item>

   


  </Accordion>
  </div>
      </Container>

  );
};

export default FAQComponent;
