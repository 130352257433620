import React, { useState } from "react";
import { FaTransgender, FaRupeeSign, FaDog, FaCat } from "react-icons/fa";
import axios from 'axios';
import Swal from "sweetalert2";
import { MdOutlinePets } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";

const PetAdoptionComp = ({ petImage, priceRange, petName, petDescription, address, gender, btnName, serviceId, mypet, petType, viewDetails }) => {
  const [loading, setLoading] = useState(false);

  const handleButtonClick = (serviceId) => {
    setLoading(true);
    window.location.href = `https://api.whatsapp.com/send/?phone=%2B919004044078&text&type=phone_number&app_absent=0`;
  };

  const handleEdit = (serviceId) => {
    setLoading(true);
    window.location.href = `/adoption/edit/${serviceId}`;
  };

  const handleToggle = (serviceId) => {
    setLoading(true);

    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}toggle/${serviceId}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('userToken')}`
      }
    };

    axios.request(config)
      .then((response) => {
        setLoading(false);
        if (response.data.status === true) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Status changed successfully.'
          }).then(() => {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while changing status.'
        });
      });
  };

  return (
    <>
      <div className="adoption-dv">
        <div className="banner-add">
          <img src={petImage} alt={petName} />
        </div>
        <div className="price-range">
          <FaRupeeSign /> {parseInt(priceRange) + 199}
        </div>
        <div className="adpd">
          <h2>{petName}</h2>
          <p>{petDescription}</p>
          <div className="breed-gender">
            <div className="column">
            <p>
            <MdOutlinePets />

               
              </p>
              <p>{address}</p>
            </div>
            <div className="column">
              <p><FaTransgender /></p>
              <p>{gender}</p>
            </div>
          </div>
          {btnName && (
            <div className="adp-btn">
              <button id="whatsapp" onClick={() => handleButtonClick(serviceId)}><FaWhatsapp />{` `} {btnName}</button>
            </div>
          )}
          {mypet && (
            <div className="adp-btn">
              <button onClick={() => handleEdit(serviceId)}>{viewDetails}</button>
              {mypet === "active" && (
                <button onClick={() => handleToggle(serviceId)} id={mypet}>
                  {loading && <span>Loading...</span>}
                  {!loading && <span>{mypet}</span>}
                </button>
              )}
              {mypet === "inactive" && (
                <button onClick={() => handleToggle(serviceId)} id={mypet}>
                  {loading && <span>Loading...</span>}
                  {!loading && <span>{mypet}</span>}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PetAdoptionComp;
