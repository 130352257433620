import React from 'react';
import CartButton from './CardButton';
const NormalService = ({ service, handleButtonClick, categoryName }) => {
  return (
    <div className="category-service-repeat">
      <div className="category-img">
        <img src={service.serviceImage} alt={`Service Image for ${service.name}`} />
        <h2>{service.serviceName}</h2>
      </div>
      <div className="category-details">
        <p id="review-div">
          <span id="review">{service.reviews} Reviews</span>
          <span id="number">{` 9.3`} </span>
        </p>
        <h3 id="price">₹ {service.servicePrice}/<span id="permonth">{service.serviceText}</span></h3>
        <div className="service-desc">
          <div dangerouslySetInnerHTML={{ __html: service.serviceDescription }} />
        </div>
        <div className="addto">
          {categoryName === "pet-grooming" || categoryName === "pet-training" || categoryName === "vet-consultation" ?
            <button id="add" onClick={() => handleButtonClick(service)}>
             Book now
            </button>
            
            :
            <button id="disable">
              Currently Not Available
            </button>
          }
        </div>
     
      </div>
    </div>
  );
}

export default NormalService;
