import React, { useState } from 'react';

const CartButton = ({ onValueChange }) => {
  const [totalItems, setTotalItems] = useState(1);

  const handleInc = () => {
    const newValue = totalItems + 1;
    setTotalItems(newValue);
    onValueChange(newValue);
  };

  const handleDec = () => {
    const newValue = totalItems > 1 ? totalItems - 1 : 1;
    setTotalItems(newValue);
    onValueChange(newValue);
  };

  return (
    <div className="booking-label">
      <div className="buttonwithinc">
        <button onClick={handleDec}>-</button>
        <p>{totalItems}</p>
        <button onClick={handleInc}>+</button>
      </div>
    </div>
  );
};

export default CartButton;
