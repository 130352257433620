import React from 'react';
import one from '../assets/media/1.png'
import two from '../assets/media/2.png'
import three from '../assets/media/3.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
const ServiceFaq = () => {
    const { categoryName } = useParams();
  return (

 <>
 {categoryName === 'pet-grooming' && 
        <Container className="faqs">
        <h2 id="faqsHead">Frequently Asked Questions.</h2>
        <Accordion defaultActiveKey="0" flush>


      <Accordion.Item eventKey="6">
        <Accordion.Header>How does doorstep pet grooming work?</Accordion.Header>
        <Accordion.Body>
         Upon booking, a professional groomer arrives at your home at the scheduled time. They will set up a mobile grooming station, bathe, trim, and groom your pet as required. Once the grooming session is complete, they clean up and leave, ensuring minimal disruption to your routine.
        </Accordion.Body>
      </Accordion.Item>
  

      <Accordion.Item eventKey="1">
        <Accordion.Header> What are the benefits of doorstep pet grooming?</Accordion.Header>
        <Accordion.Body>
        Benefits include convenience (no transportation required), reduced stress for pets (as they remain in familiar surroundings), personalized attention, and flexible scheduling options.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>What grooming services are typically offered at the doorstep?</Accordion.Header>
        <Accordion.Body>
        Most doorstep grooming services offer a range of services such as bathing, brushing, nail trimming, ear cleaning, haircuts, de-shedding treatments, and flea/tick treatments.

        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header> How long does a grooming session usually take?
</Accordion.Header>
        <Accordion.Body>
        The duration depends on the type of service and the size/breed of the pet. Typically, grooming sessions can range from 30 minutes to a few hours.

        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header> Do I need to provide anything for the grooming session?
</Accordion.Header>
        <Accordion.Body>
        You'll need to provide a space with access to water and electricity. If your pet requires specific products or has special needs.

        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header> Is it safe for my pet to be groomed at home?</Accordion.Header>
        <Accordion.Body>
        Yes, when conducted by trained professionals, doorstep grooming is safe for pets. The familiar environment can even reduce anxiety in some pets compared to traditional salon settings.

        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="7">
        <Accordion.Header> How do I book a doorstep grooming service?
</Accordion.Header>
        <Accordion.Body>
        Booking processes vary by service provider. Typically, you can book online or via phone. Ensure you provide accurate details about your pet's needs, size, and any specific requirements
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="8">
        <Accordion.Header> What precautions are taken to ensure hygiene and safety?</Accordion.Header>
        <Accordion.Body>
        Reputable groomers adhere to strict hygiene protocols, including sanitizing tools between sessions, using clean towels, and maintaining a clean workspace. They also ensure pets are handled with care and in a stress-free manner.

        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="9">
        <Accordion.Header>What if I need to cancel or reschedule my appointment?
 </Accordion.Header>
        <Accordion.Body>
        You can reschedule for free there are no additional charges as such however you have to cancel or reschedule atleast one hour before your appointment time.


        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="10">
        <Accordion.Header> Can I stay with my pet during the grooming session?
</Accordion.Header>
        <Accordion.Body>
        While some pets may find it comforting to have their owners present, it's generally recommended to allow the groomer to work without distractions. However, this can vary based on the groomer's policy and your pet's comfort level.

        </Accordion.Body>
      </Accordion.Item>


 
    </Accordion>
        </Container>

            }

            {/* //dog-walk */}
{categoryName === 'dog-walk' && 
        <Container className="faqs">
        <h2 id="faqsHead">Frequently Asked Questions.</h2>
        <Accordion defaultActiveKey="0" flush>


      <Accordion.Item eventKey="0">
        <Accordion.Header>
        What is dog walking service?
        </Accordion.Header>
        <Accordion.Body>
        A dog walking service offers professional dog walkers who take dogs out for walks, ensuring they get the necessary exercise, mental stimulation, and potty breaks during the owner's absence or busy schedule.

        </Accordion.Body>
      </Accordion.Item>
  

      <Accordion.Item eventKey="1">
        <Accordion.Header>Why is regular dog walking important?</Accordion.Header>
        <Accordion.Body>
        Regular dog walking provides essential physical exercise, mental stimulation, socialization opportunities, and helps maintain a healthy weight and behavior for dogs. It also ensures they get necessary bathroom breaks.

        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="2">
        <Accordion.Header>How long is a typical dog walking session?</Accordion.Header>
        <Accordion.Body>
        Most dog walking sessions last between 30 minutes to an hour, depending on the dog's breed, age, health, and specific needs. Some services offer shorter or longer sessions based on client preferences.

        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="3">
        <Accordion.Header>Are the dog walkers trained and experienced?</Accordion.Header>
        <Accordion.Body>
        Reputable dog walking services ensure their walkers are trained, experienced, and knowledgeable about dog behavior, safety protocols, and basic first aid. It's essential to inquire about a service's training procedures.

        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="4">
        <Accordion.Header>How do dog walkers handle emergencies?</Accordion.Header>
        <Accordion.Body>
        Professional dog walkers are trained to handle emergencies, including dog fights, injuries, or health issues. 
        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="5">
        <Accordion.Header>What information should I provide to the dog walker?</Accordion.Header>
        <Accordion.Body>
        Provide details about your dog's temperament, health conditions, vaccination records, feeding schedule, behavior around other dogs and people, and any specific instructions or concerns you have.


        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="6">
        <Accordion.Header>How do I book or schedule walks?</Accordion.Header>
        <Accordion.Body>
        We offer online booking platforms that are on our website or phone scheduling. You can typically choose recurring schedules or book on an as-needed basis
        </Accordion.Body>
      </Accordion.Item>


      <Accordion.Item eventKey="6">
        <Accordion.Header>What safety measures do dog walkers follow?</Accordion.Header>
        <Accordion.Body>
        Safety measures include using secure leashes and harnesses, avoiding busy roads or hazardous areas,, and following any specific instructions provided by the owner.

        </Accordion.Body>
      </Accordion.Item>
  


 
    </Accordion>
        </Container>

            }



                   {/* //Pet Boarding */}
{categoryName === 'pet-boarding' && 
        <Container className="faqs">
        <h2 id="faqsHead">Frequently Asked Questions.</h2>
        <Accordion defaultActiveKey="0" flush>


      <Accordion.Item eventKey="0">
        <Accordion.Header>What is pet boarding?*</Accordion.Header>
        <Accordion.Body>Pet boarding refers to a service where pet owners can leave their pets (such as dogs, cats, birds, etc.) at a facility that offers care, feeding, and accommodation for a specified period. This service ensures that pets are well-taken care of in the owner's absence.


        </Accordion.Body>
      </Accordion.Item>
  

      <Accordion.Item eventKey="1">
        <Accordion.Header>How do I prepare my pet for boarding?</Accordion.Header>
        <Accordion.Body>
        Ensure your pet's vaccinations are up-to-date.
        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="2">
        <Accordion.Header>How often will my pet be fed and exercised?</Accordion.Header>
        <Accordion.Body>
        The feeding and exercise are mentioned in the detail tab. Typically, pets are fed multiple times a day, and they receive regular exercise and playtime.
        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="3">
        <Accordion.Header>What happens if my pet falls ill during the boarding period?</Accordion.Header>
        <Accordion.Body>
        Reputable boarding facilities have protocols in place for medical emergencies. They will contact you immediately and seek veterinary care if necessary. It's crucial to provide contact details of your regular vet and give consent for treatment in case of emergencies.

        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="4">
        <Accordion.Header>Can I give my pet's own bedding or toys?*</Accordion.Header>
        <Accordion.Body>
        Yes, we allow owners to bring familiar items from home, which can provide comfort to pets. Ensure items are clearly labeled with your pet's name.

        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="5">
        <Accordion.Header>How are pets grouped or housed together?</Accordion.Header>
        <Accordion.Body>
        Pets are typically grouped based on size, temperament, and compatibility. This ensures a safe and harmonious environment. Some facilities offer private accommodations for pets that may not do well with others.

        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="6">
        <Accordion.Header>How do you handle anxious or first-time boarding pets?</Accordion.Header>
        <Accordion.Body>
        We have strategies to help anxious pets adjust, such as gradual introductions, extra attention, and using calming techniques. 

        </Accordion.Body>
      </Accordion.Item>
  


 
    </Accordion>
        </Container>

            }


{categoryName === 'vet-consultation' && 
        <Container className="faqs">
        <h2 id="faqsHead">Frequently Asked Questions.</h2>
        <Accordion defaultActiveKey="0" flush>


      <Accordion.Item eventKey="0">
        <Accordion.Header>What services do you provide during a home visit?</Accordion.Header>
        <Accordion.Body>
        Our home visit vet services include routine check-ups, vaccinations, examinations, diagnostic tests, minor procedures, and end-of-life care for pets.


        </Accordion.Body>
      </Accordion.Item>
  

      <Accordion.Item eventKey="1">
        <Accordion.Header>Do you treat all types of pets during home visits?</Accordion.Header>
        <Accordion.Body>
        Yes, we provide veterinary care for a wide range of pets including dogs, cats, small mammals, birds, and reptiles.
        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="2">
        <Accordion.Header>What are the benefits of a home visit compared to a traditional clinic visit?</Accordion.Header>
        <Accordion.Body>
        Home visits offer convenience, reduced stress for pets, personalized care in a familiar environment, and the opportunity for pet owners to discuss concerns directly with the vet.
        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="3">
        <Accordion.Header>How do I schedule a home visit for my pet?</Accordion.Header>
        <Accordion.Body>
        Simply contact our clinic to schedule a convenient appointment time for a vet to visit your home. We'll work with you to find a suitable time slot.

        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="4">
        <Accordion.Header>What safety measures do you take during home visits?</Accordion.Header>
        <Accordion.Body>
        Our veterinarians adhere to strict hygiene and safety protocols to ensure the well-being of both pets and owners during home visits. This includes wearing appropriate personal protective equipment and sanitizing equipment between appointments.

        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="5">
        <Accordion.Header>Can I request specific veterinary services during a home visit?</Accordion.Header>
        <Accordion.Body>
        Absolutely, you can discuss your pet's specific needs and any requested services with our veterinary team when scheduling the appointment.

        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="6">
        <Accordion.Header>What happens if my pet requires further treatment or procedures not possible during a home visit?</Accordion.Header>
        <Accordion.Body>
        If additional treatment or procedures are necessary, our veterinary team will provide guidance on next steps, including referral to a clinic if needed

        </Accordion.Body>
      </Accordion.Item>
  


      <Accordion.Item eventKey="8">
        <Accordion.Header>How should I prepare for a home visit from the vet?</Accordion.Header>
        <Accordion.Body>
        Prior to the visit, ensure your pet is comfortable and has access to their medical records, medications, and a quiet area for examination. If applicable, provide any specific instructions or concerns to the veterinary team.

        </Accordion.Body>
      </Accordion.Item>
  

    </Accordion>
        </Container>

            }


{categoryName === 'vet-consultation' && <>

<Container fluid className="container lg-ds">
      <Row>
        <Col lg={12} md={12} sm={12}>
           <div className="services">
             <h2>How it's works?</h2>
             <p>Browse by category</p>
           </div>

           <div className="services-dv">
             <div className="work-list">
             <img src={one} id="service"/>
             <p>Book Appointment from Our Plans<span id="small">Choose a plan that suits your pet's needs and book your appointment effortlessly.</span></p>
           
             
             </div>
             <div className="work-list">
             <img src={two} id="service"/>
             <p>Schedule<span id="small"> Pick a convenient date and time for the vet visit using our easy scheduling tool.</span></p>
           
             
             </div>
             <div className="work-list">
             <img src={three} id="service"/>
             <p>Vet Comes to You<span id="small">Relax as our vet arrives at your doorstep ready to provide expert care for your pet.</span></p>
           
             
             </div>
            
           </div>
           
        </Col>

        
      
      </Row>
    </Container>

</>} 

{categoryName === 'dog-walk' && <>

<Container fluid className="container lg-ds">
      <Row>
        <Col lg={12} md={12} sm={12}>
           <div className="services">
             <h2>How it's works?</h2>
             <p>Browse by category</p>
           </div>

           <div className="services-dv">
             <div className="work-list">
             <img src={one} id="service"/>
             <p>Select Your Plan<span id="small">Choose from our tailored dog walking plans that best match your dog's needs and your schedule.</span></p>
           
             
             </div>
             <div className="work-list">
             <img src={two} id="service"/>
             <p>Schedule<span id="small"> Pick the date and time for your dog's walk using our convenient scheduling system.</span></p>
           
             
             </div>
             <div className="work-list">
             <img src={three} id="service"/>
             <p>Professional Walker Comes to You<span id="small"> A professional dog walker arrives at your location at the scheduled time to take your furry friend for a refreshing walk.</span></p>
           
             
             </div>
            
           </div>
           
        </Col>

        
      
      </Row>
    </Container>

</>} 





{categoryName === 'pet-training' && 
        <Container className="faqs">
        <h2 id="faqsHead">Frequently Asked Questions.</h2>
        <Accordion defaultActiveKey="0" flush>


      <Accordion.Item eventKey="0">
        <Accordion.Header>What types of pets do you train?</Accordion.Header>
        <Accordion.Body>
        We offer training services for dogs of all breeds and ages.
        </Accordion.Body>
      </Accordion.Item>
  

      <Accordion.Item eventKey="1">
        <Accordion.Header>Do you provide training for specific behavior issues?</Accordion.Header>
        <Accordion.Body>
         Yes, our trainers are experienced in addressing a wide range of behavior issues including obedience training, leash pulling, aggression, anxiety, and more.
        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="2">
        <Accordion.Header>How does your door-step training service work?</Accordion.Header>
        <Accordion.Body>
        Our trainers will come to your home at a scheduled time convenient for you to provide personalized one-on-one training sessions for your pet in their familiar environment.
        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="3">
        <Accordion.Header>What training methods do you use?</Accordion.Header>
        <Accordion.Body>
        We utilize positive reinforcement techniques to encourage desired behaviors in your pet, ensuring a happy and healthy learning environment.


        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="4">
        <Accordion.Header>How many sessions are required for effective training?*</Accordion.Header>
        <Accordion.Body>
        The number of sessions needed varies depending on the individual pet and their behavior issues. Our trainers will assess your pet's needs and provide a tailored training plan.
        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="5">
        <Accordion.Header>Can I be present during the training sessions?</Accordion.Header>
        <Accordion.Body>
        Yes, we encourage pet owners to be present during the training sessions to observe the techniques used and to learn how to reinforce the training at home.

        </Accordion.Body>
      </Accordion.Item>
  
      <Accordion.Item eventKey="6">
        <Accordion.Header>Do you offer follow-up support after the training sessions?</Accordion.Header>
        <Accordion.Body>
        Absolutely, we provide ongoing support and guidance to help you maintain the training progress achieved during the sessions.

        </Accordion.Body>
      </Accordion.Item>
  

  
      <Accordion.Item eventKey="7">
        <Accordion.Header>Do you offer follow-up support after the training sessions?</Accordion.Header>
        <Accordion.Body>
        Absolutely, we provide ongoing support and guidance to help you maintain the training progress achieved during the sessions.

        </Accordion.Body>
      </Accordion.Item>
  

    
      <Accordion.Item eventKey="8">
        <Accordion.Header>What happens if my pet doesn't respond well to the training?</Accordion.Header>
        <Accordion.Body>
          Our trainers are experienced in handling various behavioral challenges. If your pet encounters difficulties, we will work closely with you to adjust the training approach as needed.
        </Accordion.Body>
      </Accordion.Item>
  

    
      <Accordion.Item eventKey="9">
        <Accordion.Header>What if I need to cancel or reschedule my appointment?</Accordion.Header>
        <Accordion.Body>
        You can reschedule for free there are no additional charges as such however you have to cancel or reschedule atleast one hour before your appointment time.
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
        </Container>

            }

{categoryName === 'pet-training' && <>

<Container fluid className="container lg-ds">
      <Row>
        <Col lg={12} md={12} sm={12}>
           <div className="services">
             <h2>How it's works?</h2>
             <p>Browse by category</p>
           </div>

           <div className="services-dv">
             <div className="work-list">
             <img src={one} id="service"/>
             <p>Browse Packages <span id="small"> . </span></p>
           
             
             </div>
             <div className="work-list">
             <img src={two} id="service"/>
             <p>Select Date & Time <span id="small"> . </span></p>
           
             
             </div>
             <div className="work-list">
             <img src={three} id="service"/>
             <p>Relax<span id="small">Our Pet Hero Is on His Way</span></p>
           
             
             </div>
            
           </div>
           
        </Col>

        
      
      </Row>
    </Container>

</>} 


 
 </>
  );
};

export default ServiceFaq;
