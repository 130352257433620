import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import DOMPurify from 'dompurify';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content';

import { css } from '@emotion/react';
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const MySwal = withReactContent(Swal);

const BookServicePet = () => {
  const [loading, setLoading] = useState(false);
  const [serviceData, setServiceData] = useState({});
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    city: "",
    petParent: "",
    ifAdoption:"",
    adoptionId:""
  });
  const userToken = localStorage.getItem("userToken");
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const url = window.location.href;
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  const sanitizedHtml = DOMPurify.sanitize(serviceData.serviceDescription);

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_HOST}service/${lastPart}`, {
          headers: {
            'Authorization': `Bearer ${userToken}`,
          },
        });
        setServiceData(response.data);
      } catch (error) {
        console.error("Error fetching service details:", error);
      }
    };

    fetchServiceDetails();
  }, [userToken]);

  const handleGoBack = () => {
    navigate.goBack();
  };

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const containerStyle = {
    height: expanded ? 'auto' : '255px',
    overflow: 'hidden',
    transition: 'height 0.3s ease-in-out 0s',
    minHeight: "0px",
    position: "relative",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleBookingSubmit = async () => {
    setLoading(true);
  
    let data = JSON.stringify({
      fullName: formData.fullName,
      email: formData.email,
      phone: formData.phone,
      city: formData.city,
      petParent: formData.petParent,
      ifAdoption:"yes",
      adoptionId:lastPart
    });
  
    let config = {
      method: 'post',
      url: 'https://ehb.easyhaibro.com/auth/v1/registration',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userToken}`
      },
      data: data
    };
  
    try {
      const response = await axios(config);
      setLoading(false);
      if (response.data.status === true) {
        MySwal.fire({
          title: 'Success!',
          text: 'Form submitted successfully!',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
        localStorage.setItem("userToken", response.data.userToken);
        window.location.reload();
      } else {
        MySwal.fire({
          title: 'Error!',
          text: 'Failed to submit form. Please try again later..',
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting form:", error);
      MySwal.fire({
        title: 'Error!',
        text: 'Failed to submit form. Please try again later.',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };
  
  return (
    <>
      <Container fluid className="container category">
        <div className="hyperlinks">
          <a href="/">Home </a> {' / '}
          <a onClick={handleGoBack} id="activeLink">{serviceData.categoryName}</a>
          <h2>{serviceData.categoryName}</h2>
        </div>

        <Container fluid className="container lg-ds">
          <Row>
            <Col lg={6} md={12} sm={12}>
              <div className="category-service-repeat book" style={containerStyle} id="containerStyle">
                <div className="category-img">
                  <img src={serviceData.serviceImage} alt="Service Thumbnail" />
                </div>
                <div className="category-details">
                  <h3 id="price">₹ {serviceData.servicePrice}</h3>
                  <p>
                    <span id="review">{serviceData.reviews} Reviews</span>{' '}
                    <span id="number">{'9.3'}</span>
                  </p>
                  <p id="descFull" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
                </div>
              </div>
              <button onClick={handleToggleExpand} id="viewMore">
                {expanded ? 'View Less' : 'View More'}
              </button>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <div className="booking-details">
                <h2>Fill All Details!</h2>
                <div className="booking-label">
                  <label>Full Name*</label>
                  <input type="text" name="fullName" value={formData.fullName} onChange={handleInputChange} placeholder="Type here" />
                </div>
                <div className="booking-label">
                  <label>Email Address*</label>
                  <input type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Type here" />
                </div>
                <div className="booking-label">
                  <label>Mobile Number*</label>
                  <input type="number" name="phone" value={formData.phone} onChange={handleInputChange} placeholder="Type here" />
                </div>
                <div className="booking-label">
                  <label>City*</label>
                  <select name="city" value={formData.city} onChange={handleInputChange}>
                    <option>Select</option>
                    <option>Bengaluru</option>
                    <option>Mumbai</option>
                  </select>
                </div>
                <div className="booking-label">
                  <label>Are you a pet parent?*</label>
                  <select name="petParent" value={formData.petParent} onChange={handleInputChange}>
                    <option>Select</option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
                <div className="booking-label">
                  <input type="submit" id="submit" value="Book Now" onClick={handleBookingSubmit} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      {loading &&
        <div className="kinetic"></div>
      }
    </>
  );
};

export default BookServicePet;
