import React from "react";
import Container from "react-bootstrap/Container";
import { AiFillApple } from "react-icons/ai";
import { IoLogoGooglePlaystore } from "react-icons/io5";
function About() {
  return (
    <Container>
      <div className="mgTagline">
        <div className="tagLeftPrivacy mobile">
          <h2>About Us</h2>
          <p>
          Welcome to RAA EASY HAI BRO SERVICES PRIVATE LIMITED, your trusted partner in providing convenient and reliable pet care services right at your doorstep. At RAA EASY HAI BRO, we understand that your pets are more than just animals – they are cherished members of your family. That's why we are dedicated to delivering top-notch pet care services designed to make both you and your furry friends happy.
          </p>
         
    
          <h4>Our Mission:</h4>
        
          <p>
          At RAA EASY HAI BRO, our mission is to make pet care easy and accessible. We strive to enhance the well-being of pets and simplify the lives of their owners by offering a range of personalized and professional services tailored to meet the unique needs of each pet.

          </p>


          <h4>What Sets Us Apart:</h4>
          <p>
            <ol>
              <li>Doorstep Convenience:** We bring the pet care experience to your doorstep, saving you time and ensuring your pets feel comfortable in their familiar surroundings.</li>
              <li>Experienced Professionals:** Our team consists of passionate and experienced pet care professionals dedicated to providing the highest standard of care for your beloved pets.</li>
             <li>Comprehensive Services:** From grooming and wellness check-ups to pet sitting and product delivery, we offer a wide range of services to cater to all aspects of your pet's well-being.</li>
           <li>Personalized Care Plans:** We understand that every pet is unique. That's why we work with you to create personalized care plans that address the specific needs and preferences of your pets.
</li>
            </ol>
          </p>


          <h4>Our Commitment to Quality</h4>
       <p>Quality is at the heart of everything we do. We prioritize the health, safety, and happiness of your pets, ensuring that they receive the attention and care they deserve. Our commitment to excellence extends to the products we offer, the professionals we employ, and the overall experience we provide to our customers.</p>
         <h4>Data Security</h4>
        

         <h4>Get in Touch</h4>
         <p>Have questions, special requests, or just want to know more about our services? Our friendly team is here to assist you. Contact us at [insert contact email/phone number], and let us show you how RAA EASY HAI BRO SERVICES PRIVATE LIMITED can make pet care easy for you.</p>


<p>Thank you for choosing RAA EASY HAI BRO – where pet care is not just a service; it's a commitment to the well-being of your cherished companions.

</p>
        </div>
      </div>
    </Container>
  );
}

export default About;
