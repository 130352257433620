import React, { useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import InputMask from 'react-input-mask';
import MySwal from "sweetalert2";
import axios from 'axios';

const Address = () => {
  const [address, setAddress] = useState('');
  const [pincode, setPincode] = useState('');
  const [houseNo, setHouseNo] = useState('');
  const [aprtmentNo, setApartmentNo] = useState('');
  const useToken = localStorage.getItem('userToken');

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const isMumbaiArea = results[0].address_components.some((component) =>
        component.types.includes('locality') &&
        (component.long_name === 'Mumbai' || component.long_name === 'Navi Mumbai' || component.short_name === 'NM' || component.long_name === 'Thane')
      );
      if (isMumbaiArea) {
        setAddress(selectedAddress);
        console.log('Selected Address:', selectedAddress);
      } else {
        await MySwal.fire({
          title: "",
          text: "Sorry! currently we are not serving in your area.",
          iconHtml: '😟',
        });
      }
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const validatePincode = (value) => {
    const allowedPatterns = ['4*', '5*'];
    for (const pattern of allowedPatterns) {
      const regex = new RegExp('^' + pattern.replace(/\*/g, '\\d*') + '$');
      if (regex.test(value)) {
        return true;
      }
    }
    return false;
  };

  const handlePincodeChange = async (event) => {
    const pincode = event.target.value;
    if (pincode.length <= 5) {
      setPincode(pincode);
    } else {
      if (validatePincode(pincode)) {
        setPincode(pincode);
      } else {
        await MySwal.fire({
          title: "",
          text: "Sorry! Currently, we are not serving in your area.",
          iconHtml: '😟',
        });
        setPincode('');
      }
    }
  };

  const handleBooking = async () => {
    const bookingData = {
      currentAddress: address,
      houseNo: houseNo,
      apartmentNo: aprtmentNo,
      pincode: pincode
    };

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'http://localhost:3001/auth/v2/booking',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${useToken}`
      },
      data: JSON.stringify(bookingData)
    };

    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='inner-section-checkout'>
      <div className='delivery-address'>
        <div>
          <div className="booking-label">
            <label>House/Flat/Block NO.*</label>
            <input type="text" value={houseNo} onChange={(e) => setHouseNo(e.target.value)} />
          </div>
          <div className="booking-label">
            <label>Apartment/Road/Area*</label>
            <input type="text" value={aprtmentNo} onChange={(e) => setApartmentNo(e.target.value)} />
          </div>
          <div className="booking-label">
            <label>Current Location*</label>
            <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <input {...getInputProps({ placeholder: 'Type Your Current Address...', className: 'location-search-input' })} />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const style = { backgroundColor: suggestion.active ? '#41b6e6' : '#fff' };
                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="booking-label">
            <label>Pincode*</label>
            <InputMask mask="999999" maskChar="" value={pincode} onChange={handlePincodeChange} placeholder="Enter Pincode" />
          </div>
        </div>
      </div>
      <button onClick={handleBooking}>Submit Booking</button>
    </div>
  );
};

export default Address;
